import { Currency } from "../types";
import { ProductStatus } from "../../api/cart/types";

export const mapCurrencyOnSymbol = (currency: Currency) => {
  switch (currency) {
    case Currency.Cny:
      return "¥";
    case Currency.Eur:
      return "€";
    case Currency.Usd:
      return "$";
    case Currency.Rub:
      return "₽";
  }
};

export const round = (number, precision: 1e2 | 1e3 = 1e2) =>
  Math.round(number * precision) / precision;

export const getFormattedTime = (dateTimeString) => {
  const date = new Date(dateTimeString);

  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const getProductRowColor = (status: ProductStatus) => {
  switch (status) {
    case ProductStatus.Received:
      return "rgba(204,255,204,0.75)";
    case ProductStatus.NotInStock:
      return "rgba(255,230,230,0.75)";
    case ProductStatus.Question:
      return "rgba(255,224,204,0.75)";
    case ProductStatus.Cancelled:
      return "rgba(204,204,204,0.75)";
    case ProductStatus.Processing:
      return "rgba(255,255,204,0.75)";
    case ProductStatus.Replaced:
      return "rgba(230,204,255,0.75)";
  }
};
