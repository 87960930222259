import React, { ForwardedRef, forwardRef } from "react";
import { DatePickerInputProps } from "./types";
import { twMerge } from "tailwind-merge";

export const DatePickerInput = forwardRef(
  (
    { value, onClick, className }: DatePickerInputProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => (
    <div className={twMerge("w-48", className)}>
      <button
        className="w-full text-sm rounded-md shadow-sm border border-gray-100 py-1 hover:bg-gray-50 text-gray-700"
        onClick={onClick}
        ref={ref}
      >
        {value}
      </button>
    </div>
  ),
);
