import { RadioGroup } from "@headlessui/react";
import React, { useState } from "react";
import { DeliveryTypeProps } from "./types";
import { useTranslation } from "react-i18next";

export const DeliveryType = React.memo(
  ({ deliveryTypes, setDeliveryType }: DeliveryTypeProps) => {
    const [selected, setSelected] = useState(0);
    const { t } = useTranslation("cart");

    const onChange = (value) => {
      setSelected(value);
      setDeliveryType && setDeliveryType(value.id);
    };

    return (
      <div className="w-full px-4 py-4">
        <h3 className="font-semibold font-2xl mb-2">
          {t("settings.delivery")}
        </h3>
        <div className="w-full">
          <RadioGroup value={selected} onChange={onChange}>
            <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
            <div className="flex flex-row flex-wrap gap-2">
              {deliveryTypes.map((deliveryType) => (
                <RadioGroup.Option
                  key={deliveryType.name}
                  value={deliveryType}
                  className={({ checked }) =>
                    `${
                      checked
                        ? "bg-slate-900 bg-opacity-75 text-white"
                        : "bg-white text-gray-900"
                    }
                    flex cursor-pointer rounded-md px-2 py-1 shadow-md focus:outline-none min-w-fit w-40`
                  }
                >
                  {() => (
                    <>
                      <div className="flex w-full items-center justify-center gap-12">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label as="p" className="font-medium">
                              {deliveryType.name}
                            </RadioGroup.Label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
    );
  },
);
