import React, { memo } from "react";
import { useRoutes } from "react-router-dom";
import { activeRoutes } from "./routes";
import { useSelector } from "react-redux";
import { getProfileFetchStatus } from "../../store/profile";
import { isTokenExists } from "../../shared/utils";

/**
 * Base router component
 */
export const Router = memo(() => {
  const profileFetchStatus = useSelector(getProfileFetchStatus);

  return useRoutes(activeRoutes(profileFetchStatus, isTokenExists()));
});
