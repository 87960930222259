import { StateAction } from "../types";
import { createFetchAction } from "../common";
import { setCurrencyRates, setSettingsFetchStatus } from "./actions";
import { profileService } from "../../api/profile";
export type AppDispatch = ReturnType<any>["dispatch"];

/**
 * Get properties action
 */
export const fetchCurrencyRates: AppDispatch =
  (): StateAction<Promise<void>> => async (dispatch) => {
    await createFetchAction<any>({
      set: (data) => {
        dispatch(setCurrencyRates(data.data));
      },
      setFetchStatus: (fetchStatus) =>
        dispatch(setSettingsFetchStatus(fetchStatus)),
      fetch: () => profileService.getRates(),
    })();
  };
