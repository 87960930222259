import React from "react";
import { useSelector } from "react-redux";
import { getCurrencyRates } from "../../../store/settings";
import { Currency } from "../../../shared/types";
import { useTranslation } from "react-i18next";

const getCurrencyString = (currency: Currency) => currency.toUpperCase();

export const CurrencyRates = React.memo(() => {
  const currencyRates = useSelector(getCurrencyRates);
  const { t } = useTranslation("footer");

  return (
    <div>
      <h5 className="text-gray-100 mb-6 text-sm font-semibold">
        {t("currency-settings")}
      </h5>
      <div
        className={`grid grid-cols-${currencyRates.length} gap-4 text-sm text-zinc-300 md:flex`}
      >
        {currencyRates.map(({ id, from, to, value }) => (
          <div key={id}>
            <div className="text-xs mb-1">
              {getCurrencyString(from)} | {getCurrencyString(to)}
            </div>
            <div className="text-white font-bold">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
});
