import React, { useCallback, useState } from "react";
import { Form } from "../../../../lib/Controls/Form";
import { Button } from "../../../../lib/Controls/Button";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { PasswordInput } from "../../../../lib/Input/Password";
import { authService } from "../../../../api/auth";

export const PasswordChangeForm = React.memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const { t } = useTranslation("profile");

  const checkMatch = () => setError(false);

  const submitForm = useCallback(async (form) => {
    if (form.password !== form.passwordConfirm) {
      setError(true);

      return;
    }

    try {
      setIsLoading(true);
      console.log(form);
      const { data } = await authService.changePassword(form);

      if (data?.success) {
        console.log("Success");
      } else {
        console.log("Failed");
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <div className="w-full max-w-sm mb-6 mt-6 px-2">
      <Form className="space-y-6" onSubmit={submitForm}>
        <div className="mb-8">
          <PasswordInput
            id={"currentPassword"}
            name={"currentPassword"}
            label={t("change-password.current-password")}
            className={"mb-6"}
          />
          <PasswordInput
            id={"password"}
            name={"password"}
            label={t("change-password.new-password")}
            onChange={checkMatch}
          />
          <PasswordInput
            id={"passwordConfirm"}
            name={"passwordConfirm"}
            label={t("change-password.new-password-confirm")}
            onChange={checkMatch}
          />
          <span
            className={twMerge(
              !error && "hidden",
              "text-xs text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block",
            )}
          >
            Passwords did not match
          </span>
        </div>
        <div className="w-full">
          <Button type="submit" isLoading={isLoading} className="w-full">
            {t("change-password.submit")}
          </Button>
        </div>
      </Form>
    </div>
  );
});
