import React from "react";
import { useTranslation } from "react-i18next";

export const Links = React.memo(() => {
  const { t } = useTranslation("footer");

  return (
    <div className="w-auto">
      <h5 className="text-gray-100 mb-6 text-sm font-semibold">{t("links")}</h5>
      <div className="text-sm text-zinc-300">
        <div>Twitter</div>
        <div>Youtube</div>
        <div>VK</div>
      </div>
    </div>
  );
});
