import React, { useState } from "react";
import { CartDataTableProps } from "./types";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../lib/Modal";
import { Carousel } from "../../../lib/Carousel";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { Product } from "../../../api/cart/types";
import { DataTable } from "../../../lib/DataTable2";
import { EditableCell } from "./Editable";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { Spin } from "../../../lib/Controls/Spin";
import { cartService } from "../../../api/cart";
import toast from "react-hot-toast";

export const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-5 h-5 text-gray-400 hover:text-gray-600"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
      />
    </svg>
  );
};

export const ProductsDataTable = React.memo(
  ({
    products,
    summary,
    title,
    isLoading,
    handleDelete,
    handleUpdate,
    hideSummary,
  }: CartDataTableProps) => {
    const [isImageLoading, setIsImageLoading] = useState<number | null>(null);
    const uploadProductImage = async (event, productId, rowId) => {
      try {
        setIsImageLoading(productId);

        const file = event.target.files[0];

        const { data } = await cartService.uploadImage(productId, file);

        if (data.success) {
          handleUpdate(products[rowId].id, {
            images: [...products[rowId].images, data.data.url],
          });
          toast.success("Изображение успешно загружено!");
        } else {
          toast.error("Проблема при загрузке изображения");
        }
      } catch (e) {
        toast.error("Проблема при загрузке изображения");
      } finally {
        setIsImageLoading(null);
      }
    };

    const { t } = useTranslation("cart");
    const [isImageModalVisible, setIsImageModalVisible] = useState(false);
    const [currentImages, setCurrentImages] = useState([]);

    const openImagesViewer = (images) => {
      setCurrentImages(images);
      setIsImageModalVisible(true);
    };

    const closeImageViewer = () => {
      setIsImageModalVisible(false);
    };

    const onUpdate = (index, column, value) => {
      handleUpdate(products[index].id, { [column]: value });
    };

    const columnHelper = createColumnHelper<Product>();

    const columns = [
      columnHelper.accessor("images", {
        header: t("products.images"),
        cell: (info) => {
          const images = info.getValue();

          return (
            <div className="flex gap-2 items-center">
              {images.length === 0 ? (
                <div className="relative sm:pl-0 pl-1 h-20 w-20 flex items-center justify-center">
                  <NoSymbolIcon className="w-6 h-6 text-gray-400" />
                </div>
              ) : (
                <div className="relative sm:pl-0 pl-1">
                  <img
                    className="min-w-20 object-cover h-20 w-20 rounded-md shadow-gray-200 shadow-sm hover:brightness-90 cursor-pointer"
                    src={images[0]}
                    alt=""
                    onClick={() => openImagesViewer(images)}
                  />
                  <span className="absolute font-semibold top-1 left-1 rounded-full bg-slate-500 opacity-80 w-3 h-3 text-xxs z-20 text-white flex flex-row justify-center items-center">
                    {images.length}
                  </span>
                </div>
              )}
              {images.length < 7 && (
                <div className="w-5">
                  <label
                    htmlFor={`fileInput-${info.row.original.id}`}
                    className="cursor-pointer"
                  >
                    {isImageLoading === info.row.original.id ? (
                      <Spin size={4} color="text-gray-400" />
                    ) : (
                      <Icon />
                    )}
                  </label>
                  <input
                    key={info.row.original.id}
                    id={`fileInput-${info.row.original.id}`}
                    type="file"
                    accept="image/png, image/jpeg"
                    className="hidden"
                    onChange={(e) => {
                      return uploadProductImage(
                        e,
                        info.row.original.id,
                        info.row.id,
                      );
                    }}
                  />
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor("title", {
        header: t("products.title"),
      }),
      columnHelper.accessor("url", {
        header: t("products.url"),
        cell: (info) => {
          const url = info.renderValue();

          return (
            <a href={url} target={"_blank"} rel="noreferrer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244"
                />
              </svg>
            </a>
          );
        },
      }),
      columnHelper.accessor((row) => row.comment, {
        id: "comment",
        header: t("products.comment"),
        cell: (row) => {
          return (
            <EditableCell<Product>
              type="textarea"
              row={row}
              onUpdate={onUpdate}
            />
          );
        },
      }),
      columnHelper.accessor("price", {
        header: t("products.price"),
        cell: (row) => {
          return (
            <div className="flex gap-2 flex-row items-center w-24">
              <div>
                <EditableCell<Product>
                  row={row}
                  onUpdate={onUpdate}
                  type="number"
                />
              </div>
              <div className="font-semibold">¥</div>
            </div>
          );
        },
        footer: () => (
          <span className="whitespace-nowrap">{t("products.totals")}</span>
        ),
      }),
      columnHelper.accessor("count", {
        header: t("products.count"),
        cell: (row) => {
          return (
            <EditableCell<Product>
              row={row}
              onUpdate={onUpdate}
              type="number"
            />
          );
        },
        footer: () => (
          <span className="whitespace-nowrap">{summary?.count}</span>
        ),
      }),
      columnHelper.accessor(
        (row) => <span className="whitespace-nowrap">{row["total-cny"]}</span>,
        {
          header: t("products.total-cny"),
          cell: (info) => info.getValue(),
          footer: () => (
            <span className="whitespace-nowrap">{summary?.cny}</span>
          ),
        },
      ),
      columnHelper.accessor(
        (row) => <span className="whitespace-nowrap">{row["total-usd"]}</span>,
        {
          header: t("products.total-usd"),
          cell: (info) => info.getValue(),
          footer: () => (
            <span className="whitespace-nowrap">{summary?.usd}</span>
          ),
        },
      ),
    ];

    if (handleDelete) {
      columns.push(
        columnHelper.accessor("id", {
          header: "",
          cell: (info) => {
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 text-red-500 hover:brightness-75 cursor-pointer"
                onClick={() => handleDelete(info.renderValue())}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            );
          },
        }) as ColumnDef<Product>,
      );
    }

    return (
      <div className="mb-6">
        <DataTable
          columns={columns}
          data={products}
          isLoading={isLoading}
          title={title}
          showSummary
          noDataTitle={"Пока у вас нет ни одного товара"}
          summary={
            !hideSummary && [
              "Totals: ",
              ...Array(columns.length - 4).fill(""),
              `${summary?.cny} ¥`,
              summary?.count,
              `${summary?.usd} $`,
            ]
          }
        />
        <Modal
          title={t("preview")}
          visible={isImageModalVisible}
          onClose={closeImageViewer}
        >
          <Carousel images={currentImages} />
        </Modal>
      </div>
    );
  },
);
