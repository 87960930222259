import axios, { AxiosRequestConfig } from "axios";
import { applyRequestInterceptor } from "./interceptors";

export const X_GURON_KEY = "kdfhgl1fiyu3gflkjw4gflkj4g847g1324oiug4";

/**
 * Base api instance
 * To use locally please use address of your local backend server, e.g. baseURL: http://localhost:9098
 */
const api = axios.create({
  timeout: 240000,
  // baseURL: "http://localhost:9091",
  baseURL: "http://api.guron.pro",
  headers: {
    "x-guron-key": X_GURON_KEY,
  },
});

applyRequestInterceptor(api);

export const getRequest = <R>(url: string, config?: AxiosRequestConfig) =>
  api.get<R>(url, config);

export const postRequest = <R>(
  url: string,
  data: unknown,
  config?: AxiosRequestConfig,
) => api.post<R>(url, data, config);

export const deleteRequest = <R>(url: string, config?: AxiosRequestConfig) =>
  api.delete<R>(url, config);

export const putRequest = <R>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig,
) => api.put<R>(url, data, config);
