import React, { useCallback, useState } from "react";
import { ProfileInfoProps } from "./types";
import { UserStatus } from "../../../api/profile/types";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Modal } from "../../../lib/Modal";
import { PasswordChangeForm } from "./PasswordChangeForm";

export const ProfileInfo = React.memo(
  ({ profile, updateProfile }: ProfileInfoProps) => {
    const [isEditMode, setIsEditMode] = useState(false);
    const [isPasswordChangeMode, setIsPasswordChangeMode] = useState(false);
    const { t } = useTranslation("profile");
    const [formData, setFormData] = useState({
      email: profile.email,
      phone: profile.phone,
    });

    const changeField = useCallback(({ target }) => {
      setFormData((formData) => ({ ...formData, [target.name]: target.value }));
    }, []);

    const setIsEditModeOn = () => setIsEditMode(true);

    const setIsEditModeOff = useCallback(() => {
      updateProfile(formData);
      setIsEditMode(false);
    }, [formData]);

    const changePassword = useCallback(() => {
      setIsPasswordChangeMode(true);
    }, []);

    return (
      <div>
        <section className="w-full mx-auto bg-gray-700 rounded-xl px-8 py-6 shadow-lg relative">
          <div className="mt-2">
            <h2 className="text-white font-semibold text-3xl tracking-wide">
              {profile.name}
            </h2>
          </div>
          <p
            className={twMerge(
              "font-semibold mt-2.5 mb-2",
              profile.status === UserStatus.Active
                ? "text-emerald-400"
                : UserStatus.Disabled
                ? "text-red-300"
                : "text-gray-400",
            )}
          >
            {t(`status.${profile.status}`)}
          </p>
          <div className="mt-3 text-white text-sm">
            <span className="text-gray-400 font-semibold mr-2 my-6">
              {t("info.email")}
            </span>
            {isEditMode ? (
              <input
                name="email"
                value={formData.email}
                onChange={changeField}
                className="bg-gray-800 py-0.5 px-1 rounded-sm outline-0"
              />
            ) : (
              <span className="py-1.5 px-1">{profile.email}</span>
            )}
          </div>
          <div className="mt-3 text-white text-sm">
            <span className="text-gray-400 font-semibold mr-2">
              {t("info.phone")}
            </span>
            {isEditMode ? (
              <input
                name="email"
                value={formData.phone}
                onChange={changeField}
                className="bg-gray-800 py-0.5 px-1 rounded-sm outline-0"
              />
            ) : (
              <span className="py-1.5 px-1">{profile.phone}</span>
            )}
          </div>
          <div className="mt-3 text-white text-sm">
            <span className="text-gray-400 font-semibold mr-2">
              {t("info.discount")}
            </span>
            <span>{profile.discount} %</span>
          </div>
          <div className="mt-3 text-white text-sm">
            <span className="text-gray-400 font-semibold mr-2">
              {t("info.wallets")}
            </span>
            {profile.wallets.map((wallet) => (
              <div key={wallet.id}>{wallet.name}</div>
            ))}
          </div>
          <div className="mt-6 flex flex-row flex-wrap gap-2">
            {isEditMode ? (
              <>
                <button
                  onClick={setIsEditModeOff}
                  className="bg-gray-600 hover:bg-gray-500 text-gray-100 text-xs py-1 px-2 rounded shadow"
                >
                  {t("info.save")}
                </button>
                <button
                  onClick={() => setIsEditMode(false)}
                  className="bg-gray-600 hover:bg-gray-500 text-gray-100 text-xs py-1 px-2 rounded shadow"
                >
                  {t("info.cancel")}
                </button>
              </>
            ) : (
              <button
                onClick={setIsEditModeOn}
                className="bg-gray-600 hover:bg-gray-500 text-gray-100 text-xs py-1 px-2 rounded shadow"
              >
                {t("info.edit")}
              </button>
            )}
            <button
              onClick={changePassword}
              className="bg-gray-600 hover:bg-gray-500 text-gray-100 text-xs py-1 px-2 rounded shadow"
            >
              {t("info.change-password")}
            </button>
          </div>
        </section>
        <Modal
          title={t("change-password.title")}
          visible={isPasswordChangeMode}
          onClose={() => setIsPasswordChangeMode(false)}
          w={"max-w-sm"}
        >
          <PasswordChangeForm />
        </Modal>
      </div>
    );
  },
);
