import { Result } from "../../shared/types";
import {getRequest} from "../index";
import { ApiResponse } from "../types";
import {Faq} from "./types";

const getFaq = ():ApiResponse<Result<Faq>> => getRequest('/faq');



export const faqService = {
  getFaq
};
