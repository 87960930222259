import React from "react";
import { Splash } from "../../containers/Splash";
import { View } from "../../containers/View";

export const Favourites = React.memo(() => {
  return (
    <Splash>
      <View>Favourites</View>
    </Splash>
  );
});
