import React from "react";
import { ViewProps } from "./types";

export const View = React.memo(({ children }: ViewProps) => {
  return (
    <div className="mx-auto max-w-8xl px-0 sm:px-4 md:px-6 lg:px-8 h-full">
      <div className="block relative bg-[#fffffd] p-6 h-full shadow-view-inset">
        {children}
      </div>
    </div>
  );
});
