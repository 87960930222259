import React, { useState } from "react";
import { PaginationProps } from "./types";
import { useTranslation } from "react-i18next";

export const Pagination = ({
  onChange,
  totalRows,
  rowsPerPage,
}: PaginationProps) => {
  const { t } = useTranslation("table");
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  const [currentPage, setCurrentPage] = useState(1);

  const onNextPage = () => {
    setCurrentPage(currentPage + 1);

    onChange(currentPage + 1);
  };

  const onPrevPage = () => {
    setCurrentPage(currentPage - 1);

    onChange(currentPage - 1);
  };

  return (
    <nav className="flex flex-row justify-end items-center w-full space-x-2 p-1 my-1">
      <div className="text-sm text-gray-500 text-right">
        {t("pagination.page")} {currentPage} {t("pagination.of")} {noOfPages}
      </div>
      <div className="flex flex-row space-x-1 items-center">
        <button
          className="text-gray-500 hover:enabled:bg-gray-100 p-0.5 rounded-md border border-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onPrevPage}
          disabled={currentPage === 1}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
        </button>
        <button
          className="text-gray-500 hover:enabled:bg-gray-100 p-0.5 rounded-md border border-gray-200 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={onNextPage}
          disabled={currentPage === noOfPages}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
            />
          </svg>
        </button>
      </div>
    </nav>
  );
};
