import { combineReducers } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { settingsReducer } from "../settings";
import { profileReducer } from "../profile";

const logger = createLogger();

/**
 * Root state
 */
export const rootReducer = combineReducers({
  settings: settingsReducer.reducer,
  profile: profileReducer.reducer,
});

/**
 * Middlewares
 */
export const middleware = (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(logger);
