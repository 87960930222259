export enum TransportCompany {
  Cdek = "cdek",
  Pek = "pek",
  DelovyeLinii = "delovye-linii",
  ZheldorExpedition = "zheldorexpedition",
  Other = "other",
}

export type Country = {
  id: number;
  name: string;
  nameRu: string;
};

export enum AddressType {
  Home = "home",
  PickPoint = "pick-point",
  Storage = "storage",
}

export type Address = {
  id: number;
  value: string;
  country: Country;
  default: boolean;
  transportCompany: TransportCompany;
  type: AddressType;
  contact: string;
  createdAt: Date;
  updatedAt: Date;
};
