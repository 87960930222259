import React, { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "./styles.css";
import { DatePickerInput } from "./Input";
import { DatePickerProps } from "./types";
import { DatepickerContainer } from "./Container";
import { subMonths } from "date-fns";

export const DatePicker = React.memo(
  ({ onChange, inputClassName }: DatePickerProps) => {
    const now = new Date();
    const [startDate, setStartDate] = useState<Date>(now);
    const [endDate, setEndDate] = useState<Date>(subMonths(now, 3));

    const onDatesChange = (dates: [Date, Date]) => {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);

      if (start && end) {
        onChange && onChange(dates);
      }
    };

    return (
      <ReactDatePicker
        onChange={onDatesChange}
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        customInput={<DatePickerInput className={inputClassName} />}
        calendarContainer={(props) => (
          <DatepickerContainer {...props} onChange={onDatesChange} />
        )}
        popperModifiers={[
          {
            name: "offset",
            options: {
              offset: [15, 0],
            },
          },
          {
            name: "preventOverflow",
            options: {
              rootBoundary: "viewport",
              tether: false,
              altAxis: true,
            },
          },
        ]}
      />
    );
  },
);
