import React, { useCallback, useEffect, useState } from "react";
import {
  Address,
  AddressType,
  Country,
  Nullable,
} from "../../../../shared/types";
import { AddressListProps } from "./types";
import { profileService } from "../../../../api/profile";
import { useTranslation } from "react-i18next";
import { AddAddressForm } from "../AddForm";
import { getTransportCompanyTitle } from "../helper";
import { Button } from "../../../../lib/Controls/Button";
import {
  addAddress,
  removeAddress,
  updateAddress,
} from "../../../../store/profile/actions";
import { useDispatch } from "react-redux";
import { EditAddressForm } from "../EditForm";

export const AddressList = React.memo(({ addresses }: AddressListProps) => {
  const { t } = useTranslation("profile");
  const [isAddingAddress, setIsAddingAddress] = useState(false);
  const [isEditingAddress, setIsEditingAddress] =
    useState<Nullable<number>>(null);
  const dispatch = useDispatch();
  const [countriesList, setCountriesList] = useState<Country[]>([]);

  const fetchCountriesList = useCallback(async () => {
    try {
      const { data } = await profileService.getCountriesList();

      if (data.success) {
        setCountriesList(data.data);
      } else {
        console.log(t("address-form.countries-list-error"));
      }
    } catch (e) {
      console.log(t("address-form.countries-list-error"));
    }
  }, []);

  const onCloseAddingAddress = () => setIsAddingAddress(false);
  const onCloseEditingAddress = () => setIsEditingAddress(null);

  const onAppendAddress = useCallback((address: Address) => {
    dispatch(addAddress(address));
  }, []);

  const onUpdateAddress = useCallback((id, address: Address) => {
    dispatch(updateAddress({ id, address }));
  }, []);

  const onRemoveAddress = useCallback(async (addressId: number) => {
    try {
      await profileService.removeAddress(addressId);

      dispatch(removeAddress(addressId));
    } catch (e) {
      console.log(e);
    }
  }, []);

  const onEditAddress = useCallback((addressId) => {
    setIsEditingAddress(addressId);
  }, []);

  const addressTypes = [
    { id: AddressType.Home, title: t("address-form.type-home") },
    { id: AddressType.PickPoint, title: t("address-form.type-pick-point") },
    { id: AddressType.Storage, title: t("address-form.type-storage") },
  ];

  useEffect(() => {
    fetchCountriesList();
  }, []);

  return (
    <div>
      <h3 className="text-2xl text-gray-700 mb-4 font-semibold ml-0.5">
        {t("addresses-title")}
      </h3>
      {addresses.map((address) => (
        <div
          className="flex w-full items-center justify-between gap-12 p-1"
          key={address.id}
        >
          <div className="flex w-full border-l-4 border-gray-500 items-center rounded-br-md rounded-tr-md shadow-sm bg-gray-50 py-2 px-3">
            {isEditingAddress === address.id ? (
              <EditAddressForm
                address={address}
                onClose={onCloseEditingAddress}
                updateAddress={onUpdateAddress}
                addressTypes={addressTypes}
                countriesList={countriesList}
              />
            ) : (
              <div className="text-md w-full">
                <div className="flex flex-row justify-between w-full">
                  <div className="flex flex-row">
                    <div className="mr-4 font-semibold text-gray-500">
                      {address.country?.nameRu || address.country.name}
                    </div>
                    <div>{address.value}</div>
                  </div>
                  <div className="flex flex-row gap-1">
                    <div
                      title="Default"
                      onClick={() => onEditAddress(address.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-gray-500 hover:text-gray-600 cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </div>
                    <div
                      title="Default"
                      onClick={() => onRemoveAddress(address.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 text-gray-500 hover:text-gray-600 cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="flex mt-2 flex-row gap-2 text-sm">
                  <div>
                    {address.type === AddressType.Home ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                        />
                      </svg>
                    )}
                  </div>
                  <div>
                    {getTransportCompanyTitle(address.transportCompany)}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
      {isAddingAddress ? (
        <AddAddressForm
          onClose={onCloseAddingAddress}
          appendAddress={onAppendAddress}
          countriesList={countriesList}
          addressTypes={addressTypes}
        />
      ) : (
        <Button
          className="mt-4 ml-0.5"
          size="sm"
          onClick={() => setIsAddingAddress(true)}
        >
          {t("address-form.add-button")}
        </Button>
      )}
    </div>
  );
});
