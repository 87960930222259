import React from "react";
import { Cart } from "../../views/Cart";
import { Orders } from "../../views/Orders";
import { Login } from "../../views/Auth/Login";
import { Navigate } from "react-router-dom";
import { Faq } from "../../views/Faq";
import { FetchStatus } from "../../store/enums";
import { Main } from "../../views/Main";
import { Routes } from "../../config/routes";
import { LoaderOverlay } from "../../lib/LoaderOverlay";
import { Order } from "../../views/Order";
import { Profile } from "../../views/Profile";
import { Registration } from "../../views/Auth/Registration";
import { Favourites } from "../../views/Favourites";

/**
 * List of active routes
 * @see routes
 */
export const activeRoutes = (profileFetchStatus, isTokenExists) => {
  const protect = (component) => {
    if (!isTokenExists) {
      return <Navigate to={Routes.Login} />;
    }

    switch (profileFetchStatus) {
      case FetchStatus.Initial:
      case FetchStatus.Loading:
        return <LoaderOverlay />;
      case FetchStatus.Error:
        return <Navigate to={Routes.Login} />;
      case FetchStatus.Complete:
      default:
        return component;
    }
  };

  const authorized = (component) =>
    profileFetchStatus === FetchStatus.Complete ? (
      <Navigate to={Routes.Main} />
    ) : (
      component
    );

  return [
    {
      path: Routes.Main,
      element: <Main />,
    },
    {
      path: Routes.Orders,
      element: protect(<Orders />),
    },
    {
      path: Routes.Order,
      element: protect(<Order />),
    },
    {
      path: Routes.Cart,
      element: protect(<Cart />),
    },
    {
      path: Routes.Favourites,
      element: protect(<Favourites />),
    },
    {
      path: Routes.Profile,
      element: protect(<Profile />),
    },
    {
      path: Routes.Login,
      element: authorized(<Login />),
    },
    {
      path: Routes.Registration,
      element: authorized(<Registration />),
    },
    {
      path: Routes.Faq,
      element: <Faq />,
    },
    {
      path: "*",
      element: protect(<div>Error</div>),
    },
  ];
};
