import { initReactI18next } from "react-i18next";
import i18n from "i18next";

// RU
import header from "./ru/components/header.json";
import footer from "./ru/components/footer.json";
import parser from "./ru/components/parser.json";
import table from "./ru/components/table.json";

import auth from "./ru/views/auth.json";
import order from "./ru/views/order.json";
import cart from "./ru/views/cart.json";
import profile from "./ru/views/profile.json";
import main from "./ru/views/main.json";

export const resources = {
  ru: {
    header,
    footer,
    parser,
    table,
    auth,
    profile,
    cart,
    order,
    main
  },
};

i18n.use(initReactI18next).init({
  lng: "ru",
  resources,
});
