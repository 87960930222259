import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import guronLogo from "../../assets/images/guron_logo.png";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../../api/auth";
import { flushUserProfile } from "../../store/profile/thunk";
import {
  getProfile,
  getProfileFetchStatus,
  getUserItems,
} from "../../store/profile";
import { FetchStatus } from "../../store/enums";
import { Spin } from "../../lib/Controls/Spin";
import { Routes } from "../../config/routes";
import { twMerge } from "tailwind-merge";

export const Header = React.memo(() => {
  const { t } = useTranslation("header");
  const [current, setCurrent] = useState<Routes>(Routes.Main);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const profileFetchStatus = useSelector(getProfileFetchStatus);
  const profile = useSelector(getProfile);
  const userItems = useSelector(getUserItems);
  const navigate = useNavigate();

  const isAuthorized = profileFetchStatus === FetchStatus.Complete;

  const logout = useCallback(async () => {
    try {
      await authService.logOut();

      dispatch(flushUserProfile());
      navigate(Routes.Login);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const navigation = [
    {
      name: t("navigation.orders"),
      href: Routes.Orders,
      count: userItems.ordersCount,
      auth: true,
    },
    {
      name: t("navigation.cart"),
      href: Routes.Cart,
      count: userItems.cartItemsCount,
      auth: true,
    },
    {
      name: t("navigation.favourites"),
      href: Routes.Favourites,
      auth: true,
    },
    { name: t("navigation.faq"), href: Routes.Faq },
  ];

  const userNavigation = [
    { name: t("navigation.profile"), href: Routes.Profile },
    { name: t("navigation.logout"), href: "#", action: logout },
  ];

  useEffect(() => {
    const currentRoute = `/${pathname.split("/")[1]}`;

    setCurrent(currentRoute as Routes);
  }, [pathname]);

  const getMenuItems = () => {
    if (isAuthorized) {
      return navigation;
    }

    return navigation.filter(({ auth }) => !auth);
  };

  const getProfileMenu = useCallback(() => {
    switch (profileFetchStatus) {
      case FetchStatus.Complete:
        return (
          <div className="hidden md:block">
            <div className="ml-4 flex items-center md:ml-6">
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-400">
                    <span className="sr-only">Open user menu</span>
                    <div className="h-8 w-8 rounded-full flex items-center justify-center bg-blue-100">
                      {profile?.name[0]}
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map(({ name, href, action }) => (
                      <Menu.Item key={name}>
                        {({ active }) => (
                          <Link
                            to={href}
                            onClick={action}
                            className={twMerge(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}
                          >
                            {name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        );
      case FetchStatus.Loading:
      case FetchStatus.Updating:
        return <Spin size={8} mr={0} />;
      case FetchStatus.Initial:
      case FetchStatus.Error:
        return (
          <div className="flex flex-row gap-2">
            <Link
              className={twMerge(
                Routes.Registration === current
                  ? "bg-gray-900 text-white"
                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                "rounded-md px-3 py-2 text-sm font-medium",
              )}
              to={Routes.Registration}
            >
              {t("navigation.registration")}
            </Link>
            <Link
              className={twMerge(
                Routes.Login === current
                  ? "bg-gray-900 text-white"
                  : "text-gray-300 hover:bg-gray-700 hover:text-white",
                "rounded-md px-3 py-2 text-sm font-medium",
              )}
              to={Routes.Login}
            >
              {t("navigation.login")}
            </Link>
          </div>
        );
    }
  }, [profileFetchStatus, current]);

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 cursor-pointer"
                      onClick={() => navigate(Routes.Main)}
                      src={guronLogo}
                      alt="Guron"
                    />
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {getMenuItems().map(({ name, href, count }) => (
                        <Link
                          key={name}
                          to={href}
                          className={twMerge(
                            href === current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "rounded-md px-3 py-2 text-sm font-medium",
                          )}
                          aria-current={href === current ? "page" : undefined}
                        >
                          {name}
                          {count !== undefined && (
                            <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs text-blue-800 bg-blue-100 rounded-full">
                              {count}
                            </span>
                          )}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                {getProfileMenu()}
                {isAuthorized && <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>}
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={twMerge(
                      item.href === current
                        ? "bg-gray-900 text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium",
                    )}
                    aria-current={item.href === current ? "page" : undefined}
                  >
                    {item.name}
                    {item.count !== undefined && (
                        <span className="inline-flex items-center justify-center w-4 h-4 ml-2 text-xs text-blue-800 bg-blue-100 rounded-full">
                          {item.count}
                        </span>
                    )}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-gray-700 pb-3 pt-4">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <div className="h-8 w-8 rounded-full flex items-center justify-center bg-blue-100">
                      {profile?.name[0]}
                    </div>
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium leading-none text-white">
                      {profile?.name}
                    </div>
                    <div className="text-sm font-medium leading-none text-gray-400">
                      {profile?.email}
                    </div>
                  </div>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {userNavigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
});
