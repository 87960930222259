import { RadioGroup } from "@headlessui/react";
import React, { useCallback, useEffect, useState } from "react";
import { CartAddressProps } from "./types";
import { useTranslation } from "react-i18next";
import { AddAddressForm } from "../../Profile/Address/AddForm";
import { Address, AddressType, Country } from "../../../shared/types";
import { addAddress } from "../../../store/profile/actions";
import { useDispatch } from "react-redux";
import { profileService } from "../../../api/profile";
import { Button } from "../../../lib/Controls/Button";
import { getTransportCompanyTitle } from "../../Profile/Address/helper";

export const CartAddress = React.memo(
  ({ userAddresses, setAddress, setAddressString }: CartAddressProps) => {
    const { t } = useTranslation("cart");
    const dispatch = useDispatch();
    const [selected, setSelected] = useState("");
    const [manualAddress, setManualAddress] = useState("");
    const [isAddingAddress, setIsAddingAddress] = useState(false);
    const [countriesList, setCountriesList] = useState<Country[]>([]);

    const addressTypes = [
      { id: AddressType.Home, title: t("address-form.type-home") },
      { id: AddressType.PickPoint, title: t("address-form.type-pick-point") },
      { id: AddressType.Storage, title: t("address-form.type-storage") },
    ];

    const onAppendAddress = useCallback((address: Address) => {
      dispatch(addAddress(address));
    }, []);

    const onChange = (value: any) => {
      setSelected(value);
      setAddress && setAddress(value);
    };

    useEffect(() => {
      if (!userAddresses || userAddresses.length === 0) {
        return;
      }

      const defaultAddressIndex =
        userAddresses.findIndex((address) => address.default) || 0;

      onChange(userAddresses[defaultAddressIndex]?.id);
    }, [userAddresses]);

    const fetchCountriesList = useCallback(async () => {
      try {
        const { data } = await profileService.getCountriesList();

        if (data.success) {
          setCountriesList(data.data);
        } else {
          console.log(t("address-form.countries-list-error"));
        }
      } catch (e) {
        console.log(t("address-form.countries-list-error"));
      }
    }, []);

    const onManualAddressInputChange = ({ target }) => {
      setManualAddress(target.value);
      setAddressString && setAddressString(target.value);

      if (target.value) {
        setSelected("");
        setAddress(null);
      }
    };

    useEffect(() => {
      fetchCountriesList();
    }, []);

    return (
      <div className="w-full px-4 py-4">
        <h3 className="font-semibold font-2xl mb-2">{t("settings.address")}</h3>
        <div className="w-full flex flex-col">
          <div className="w-full flex flex-col sm:flex-row gap-4">
            <RadioGroup value={selected} onChange={onChange}>
              <div className="flex flex-row flex-wrap gap-2">
                {userAddresses.map(
                  ({ id, value, transportCompany, country }) => (
                    <RadioGroup.Option
                      key={value}
                      value={id}
                      className={({ checked }) =>
                        `${
                          checked
                            ? "bg-slate-900 bg-opacity-75 text-white"
                            : "bg-white text-gray-900"
                        }
                    flex cursor-pointer rounded-lg px-2 py-1 shadow-md focus:outline-none min-w-fit`
                      }
                    >
                      {() => (
                        <>
                          <div className="flex w-full items-center justify-between gap-12 p-1">
                            <div className="flex items-center">
                              <div className="text-sm">
                                <RadioGroup.Label
                                  as="p"
                                  className="font-medium"
                                >
                                  {country.nameRu}, {value}{" "}
                                  {transportCompany && (
                                    <>
                                      <span>,&nbsp;</span>
                                      <b>
                                        {getTransportCompanyTitle(
                                          transportCompany,
                                        )}
                                      </b>
                                    </>
                                  )}
                                </RadioGroup.Label>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ),
                )}
              </div>
            </RadioGroup>
            {!isAddingAddress && (
              <Button
                size="sm"
                view="ghost"
                className="w-fit whitespace-nowrap max-h-9 px-4"
                onClick={() => setIsAddingAddress(true)}
              >
                {t("address-form.add-button")}
              </Button>
            )}
          </div>
          {isAddingAddress && (
            <AddAddressForm
              onClose={() => setIsAddingAddress(false)}
              appendAddress={onAppendAddress}
              countriesList={countriesList}
              addressTypes={addressTypes}
            />
          )}
        </div>
        <div className="mt-4">
          <div className="inline-flex items-center w-full mb-1">
            <hr className="w-64 h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />
            <span className="text-sm absolute px-3 font-medium text-gray-600 -translate-x-1/2 -translate-y-0.5 bg-white left-36">
              или
            </span>
          </div>
          <div className="flex flex-col mb-2">
            <label className="text-sm mb-2 text-gray-600 font-semibold">
              Введите адрес вручную (без сохранения в профиле)
            </label>
            <input
              className="w-full md:w-2/3 border border-1 border-gray-300 rounded-md px-2 py-1 text-sm text-slate-900 mb-1"
              placeholder="Россия, 199000, Москва, ул. Строителей, д. 11б, кв. 123, Иванов Иван Иванович, +79991112233"
              onChange={onManualAddressInputChange}
              value={manualAddress}
            />
            <span className="text-xs text-gray-600">
              Полный адрес с индексов, ФИО и телефон получателя (если отличается
              от профиля)
            </span>
          </div>
        </div>
      </div>
    );
  },
);
