import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FetchStatus } from "../enums";
import {
  ProfileReducer,
  ProfileState,
  UserItemsCount,
  UserOrdersAndCartItemsCount,
} from "./types";
import { Profile } from "../../api/profile/types";
import { Address } from "../../shared/types";

export const profileReducer = createSlice<ProfileState, ProfileReducer>({
  name: "profile",
  initialState: {
    fetchStatus: FetchStatus.Initial,
    entity: null,
  },
  reducers: {
    setProfile(state: ProfileState, action: PayloadAction<Profile>) {
      state.entity = action.payload;
    },
    updateProfile(state: ProfileState, action: PayloadAction<Profile>) {
      state.entity = { ...state.entity, ...action.payload };
    },
    setOrdersCount(state: ProfileState, action: PayloadAction<UserItemsCount>) {
      state.entity.ordersCount = action.payload;
    },
    addAddress(state: ProfileState, action: PayloadAction<Address>) {
      state.entity.addresses = [...state.entity.addresses, action.payload];
    },
    updateAddress(
      state: ProfileState,
      action: PayloadAction<{
        id: Address["id"];
        address: Address;
      }>,
    ) {
      const { address, id } = action.payload;
      const updatingAddressIndex = state.entity.addresses.findIndex(
        (address) => address.id === id,
      );
      const currentAddress = state.entity.addresses[updatingAddressIndex];

      state.entity.addresses[updatingAddressIndex] = {
        ...currentAddress,
        ...address,
      };
    },
    removeAddress(state: ProfileState, action: PayloadAction<Address["id"]>) {
      state.entity.addresses = [...state.entity.addresses].filter(
        ({ id }) => id !== action.payload,
      );
    },
    setCartItemsCount(
      state: ProfileState,
      action: PayloadAction<UserItemsCount>,
    ) {
      state.entity.cartItemsCount = action.payload;
    },
    setOrdersAndCartItemsCount(
      state: ProfileState,
      action: PayloadAction<UserOrdersAndCartItemsCount>,
    ) {
      const { ordersCount, cartItemsCount } = action.payload;

      state.entity.cartItemsCount = cartItemsCount;
      state.entity.ordersCount = ordersCount;
    },
    flushProfile(state: ProfileState) {
      state.entity = null;
    },
    setProfileFetchStatus(
      state: ProfileState,
      action: PayloadAction<FetchStatus>,
    ) {
      state.fetchStatus = action.payload;
    },
  },
});

export const {
  setProfile,
  flushProfile,
  addAddress,
  updateAddress,
  removeAddress,
  setProfileFetchStatus,
  setOrdersCount,
  setCartItemsCount,
  updateProfile,
  setOrdersAndCartItemsCount,
} = profileReducer.actions;
