import React, { useCallback, useState } from "react";
import { Button } from "../../lib/Controls/Button";
import { useTranslation } from "react-i18next";
import { cartService } from "../../api/cart";
import { Area } from "../../shared/types";
import { ParserProps } from "./types";
import { twMerge } from "tailwind-merge";
import {orderCartService} from "../../api/order-cart";

export const Parser = React.memo(
  ({ onSuccess, onFail, hideTitle, type = "in-cart", orderId }: ParserProps) => {
    const [url, setUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation("parser");

    const handleEnterPress = async (
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (event.key === "Enter") {
        await parseProductUrl();
      }
    };

    const parseProductUrl = useCallback(async () => {
      try {
        setIsLoading(true);

        let data

        if(type === "in-cart"){
            const response = await cartService.addToCart({ url, area: Area.Cn });
            data = response.data
        }else if(type === "in-order-cart") {
            const response = await orderCartService.addItem( orderId,{ url, area: Area.Cn });
            data = response.data
        }

        if (data.success) {
          onSuccess && onSuccess(data.data);
          setUrl("");
        } else {
          onFail && onFail();
        }
      } catch (e) {
        console.log("Error: ", e);
        onFail && onFail();
      } finally {
        setIsLoading(false);
      }
    }, [url]);

    return (
      <>
        {!hideTitle && (
          <h2 className="text-gray-700 mb-2 font-semibold text-2xl">
            {type === "in-cart" ? t("add-to-cart"): t("add-to-order-cart")}
          </h2>
        )}
        <label
          htmlFor="search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          {t("parse-and-add")}
        </label>
        <div className="relative mb-6">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            type="search"
            id="search"
            onKeyDown={handleEnterPress}
            value={url}
            disabled={isLoading}
            onChange={({ target }) => setUrl(target.value)}
            className={twMerge(
              "block w-full p-3.5 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-gray-300 focus:border-gray-400focus:outline-0",
              isLoading && "opacity-75",
            )}
            placeholder={t("parse-and-add")}
            required
          />
          <Button
            onClick={parseProductUrl}
            isLoading={isLoading}
            disabled={url.length === 0}
            className="absolute right-2 bottom-2 px-4 py-2"
          >
            {t("parse-and-add")}
          </Button>
        </div>
      </>
    );
  },
);
