export const buildParams = (data) => {
  if (!data || Object.keys(data).length === 0) {
    return "";
  }

  console.log("data: ", Object.entries(data));

  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    console.log("key: ", key);
    console.log("value: ", value);

    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value));
    } else {
      // @ts-ignore
      params.append(key, value);
    }
  });

  return params.toString();
};
