import React, { Fragment, useCallback, useState } from "react";
import { OrdersDataTableProps } from "./types";
import { useTranslation } from "react-i18next";
import { getFormattedTime } from "../../../shared/utils";
import { Link } from "react-router-dom";
import { Routes } from "../../../config/routes";
import { OrderStatus } from "../../OrderStatus";
import { OrderStatus as OrderStatusEnum } from "../../../api/order/types";
import { twMerge } from "tailwind-merge";
import { DataTable } from "../../../lib/DataTable2";
import { createColumnHelper } from "@tanstack/react-table";
import { OrderListItem } from "../../../api/order/types";
import { ProductsPreview } from "../ProductsPreview";
import { DatePicker } from "../../../lib/Datepicker";
import { Pagination } from "../../../lib/Controls/Pagination";
import { DEFAULT_PAGE_SIZE } from "../../../shared/constants/pagination";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

const getBalance = (data: object): number =>
  Number(data["payment.total-usd"]) - Number(data["cost.total-usd"]);

const orderStatuses = [OrderStatusEnum.Placed, OrderStatusEnum.Cancelled];

export const OrdersDataTable = React.memo(
  ({
    orders,
    isLoading,
    onDatesChange,
    onPageChange,
    onStatusesChange,
    totalOrdersCount,
  }: OrdersDataTableProps) => {
    const { t } = useTranslation("order");
    const [selectedOrderStatuses, setSelectedOrderStatuses] = useState<
      OrderStatusEnum[]
    >([]);

    const columnHelper = createColumnHelper<OrderListItem>();

    const selectOrderStatuses = useCallback((statuses: OrderStatusEnum[]) => {
      setSelectedOrderStatuses(statuses);

      onStatusesChange(statuses);
    }, []);

    const columns = [
      columnHelper.accessor("id", {
        header: () => (
          <div className="relative">
            <input
              className="text-xs border border-gray-200 rounded-sm px-1 py-0.5 max-w-[5rem]"
              placeholder={t("table.id")}
            />
          </div>
        ),
        cell: (info) => {
          const id = info.getValue();

          return (
            <Link to={`${Routes.Orders}/${id}`}>
              <div className="p-2 hover:brightness-95 font-semibold rounded flex bg-gray-50 flex-row items-center justify-between gap-1">
                <div>{id}</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5 text-gray-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9zm3.75 11.625a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          );
        },
      }),
      columnHelper.accessor(
        (row) => <ProductsPreview productPreviews={row.products} />,
        {
          id: "products",
          header: t("table.product-previews"),
          cell: (info) => info.renderValue(),
        },
      ),
      columnHelper.accessor((row) => getFormattedTime(row.createdAt), {
        id: "createdAt",
        header: t("table.created-at"),
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => <OrderStatus status={row.status} />, {
        id: "status",
        header: t("table.status"),
        cell: (info) => info.renderValue(),
      }),
      columnHelper.display({
        cell: (data) => data.row.original["cost.total-cny"],
        header: t("table.cost-total-cny"),
      }),
      columnHelper.display({
        cell: (data) => data.row.original["cost.total-usd"],
        header: t("table.cost-total-usd"),
      }),
      columnHelper.display({
        cell: (data) => data.row.original["payment.total-usd"],
        header: t("table.payment-total-usd"),
      }),
      columnHelper.accessor(
        (row) => {
          const balance = getBalance(row);

          return (
            <span
              className={twMerge(
                "px-1 py-0.5 rounded-sm font-bold",
                balance < 0 ? "text-red-700" : "text-green-700",
              )}
            >
              {balance.toFixed(2)} $
            </span>
          );
        },
        {
          header: t("table.balance"),
          cell: (info) => info.renderValue(),
        },
      ),
      columnHelper.accessor(
        (row) => (
          <span>
            {row.locked && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                />
              </svg>
            )}
          </span>
        ),
        {
          header: t("table.locked"),
          cell: (info) => info.renderValue(),
        },
      ),
    ];

    return (
      <div>
        <div className="flex flex-row justify-between align-baseline flex-wrap">
          <div>
            <h2 className="text-gray-700 mb-2 text-2xl font-semibold">
              {t("table.title")}
            </h2>
          </div>
          <div className="flex flex-row items-center mb-2">
            <Listbox
              value={selectedOrderStatuses}
              onChange={selectOrderStatuses}
              multiple
            >
              <div className="relative">
                <Listbox.Button className="text-sm cursor-pointer hover:bg-gray-50 relative w-full rounded-md bg-white py-1 pl-3 border border-gray-100 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                  <span className="block truncate text-gray-700">
                    {selectedOrderStatuses.length > 0 ? (
                      selectedOrderStatuses
                        .map((orderStatus) => t(`status.${orderStatus}`))
                        .join(", ")
                    ) : (
                      <span className="text-gray-400">{t("status.all")}</span>
                    )}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="min-w-fit absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-100">
                    {orderStatuses.map((orderStatus) => (
                      <Listbox.Option
                        key={orderStatus}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 pl-10 text-sm pr-4 ${
                            active
                              ? "bg-gray-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                        value={orderStatus}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {t(`status.${orderStatus}`)}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-400">
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
            <DatePicker onChange={onDatesChange} inputClassName="ml-2" />
          </div>
        </div>
        <DataTable
          columns={columns}
          data={orders}
          isLoading={isLoading}
          noDataTitle={t("empty-list")}
        />
        <Pagination
          totalRows={totalOrdersCount}
          onChange={onPageChange}
          rowsPerPage={DEFAULT_PAGE_SIZE}
        />
      </div>
    );
  },
);
