import { deleteRequest, getRequest, postRequest, putRequest } from "../index";
import { ApiResponse } from "../types";
import { CurrencyRate, GetProfileResponse, Profile } from "./types";
import { Address, Country, Result } from "../../shared/types";

const getProfile = (): GetProfileResponse => getRequest("/profile");

const updateProfile = (data): ApiResponse<Result<Profile>> =>
  putRequest("/profile", data);

const addAddress = (body): ApiResponse<Result<Address>> =>
  postRequest("/profile/address", body);

const removeAddress = (addressId): ApiResponse<void> =>
  deleteRequest(`/profile/address/${addressId}`);

const updateAddress = (addressId, data): ApiResponse<Result<Address>> =>
  putRequest(`/profile/address/${addressId}`, data);

const getRates = (): ApiResponse<CurrencyRate[]> =>
  getRequest("/profile/rates");

const getCountriesList = (): ApiResponse<Result<Country[]>> =>
  getRequest("/profile/countries");

export const profileService = {
  getProfile,
  addAddress,
  getRates,
  removeAddress,
  updateAddress,
  getCountriesList,
  updateProfile,
};
