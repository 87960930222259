import React from "react";
import { ToastBar, Toaster } from "react-hot-toast";

export const ToastProvider = () => {
  return (
    <Toaster>
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            fontSize: "13px",
            ...t.style,
            animation: t.visible
              ? "custom-enter 1s ease"
              : "custom-exit 1s ease",
          }}
        />
      )}
    </Toaster>
  );
};
