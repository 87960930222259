import { State } from "../root/types";
import { StateSelector } from "../types";
import { createSelector } from "@reduxjs/toolkit";
import { CurrencyRate } from "../../api/profile/types";

export const getSettingsState = (state: State) => state.settings;

export const getCurrencyRates: StateSelector<CurrencyRate[]> = createSelector(
  getSettingsState,
  ({ entity }) => entity?.currencyRates || [],
);
