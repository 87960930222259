import React from "react";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { twMerge } from "tailwind-merge";
import { DataTableProps } from "./types";

export const DataTable = React.memo(
  ({
    data,
    columns,
    condensed,
    isLoading,
    noDataTitle = "No data",
    showSummary,
    getRowStyles,
  }: DataTableProps) => {
    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      manualPagination: true,
    });

    const paddingTdStyled = condensed
      ? "md:px-3 md:py-2 sm:px-2 sm:py-2 px-2 py-1"
      : "md:px-6 md:py-3 sm:px-3 sm:py-2 px-3 py-2 whitespace-nowrap w-fit";

    const paddingThStyled = condensed
      ? "md:px-3 md:py-3 sm:px-2 sm:py-2 px-2 py-1"
      : "md:px-6 md:py-4 sm:px-3 sm:py-2 px-3 py-2";

    const getContent = () => {
      if (isLoading) {
        return (
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden bg-gray-100 opacity-50 flex flex-col items-center justify-center">
            <div className="absolute top-[45%] loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
          </div>
        );
      }

      if (data.length === 0) {
        return (
          <div className="absolute top-0 left-0 right-0 bottom-0 w-full flex flex-col items-center justify-center">
            <h3 className="text-2xl text-gray-300">{noDataTitle}</h3>
          </div>
        );
      }
    };

    return (
      <div>
        <div
          className="relative overflow-x-auto shadow-md rounded-md max-h-full"
          style={{ minHeight: "11rem" }}
        >
          {getContent()}
          <table
            className={twMerge(
              "w-full text-sm text-left text-gray-600 bg-white",
              isLoading && "blur-sm",
            )}
            style={{ minHeight: "11rem" }}
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-100">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className={twMerge(paddingThStyled, "whitespace-nowrap")}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, i) => (
                <tr
                  key={row.id}
                  className={twMerge(
                    i % 2 ? "bg-gray-50" : "bg-white",
                    "border-b",
                  )}
                  style={
                    typeof getRowStyles === "function" ? getRowStyles(row) : {}
                  }
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      title={cell.getValue() as string}
                      className={twMerge(
                        paddingTdStyled,
                        "overflow-hidden overflow-ellipsis",
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            {showSummary && (
              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id} className={paddingThStyled}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.footer,
                              header.getContext(),
                            )}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            )}
          </table>
        </div>
      </div>
    );
  },
);
