import React, { useCallback, useEffect, useState } from "react";
import { Button } from "../../lib/Controls/Button";
import { cartService } from "../../api/cart";
import { ProductsDataTable } from "../../components/Products/DataTable";
import { Parser } from "../../components/Parser";
import {
  setCartItemsCount,
  setOrdersAndCartItemsCount,
} from "../../store/profile/actions";
import { useDispatch, useSelector } from "react-redux";
import { View } from "../../containers/View";
import { CartSummary } from "../../api/cart/types";
import { Area, Currency } from "../../shared/types";
import { Splash } from "../../containers/Splash";
import { useTranslation } from "react-i18next";
import { CartInfo } from "../../components/CartInfo";
import { Routes } from "../../config/routes";
import { useNavigate } from "react-router-dom";
import { orderService } from "../../api/order";
import { LoaderOverlay } from "../../lib/LoaderOverlay";
import { getUserItems } from "../../store/profile";
import { toast } from "react-hot-toast";

export const Cart = React.memo(() => {
  const [summary, setSummary] = useState<CartSummary>();
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("cart");
  const userItems = useSelector(getUserItems);
  const [orderData, setOrderData] = useState({
    address: null,
    addressString: "",
    paymentMethod: null,
    deliveryType: null,
  });

  const disabledCreation =
    (!orderData.address && !orderData.addressString) ||
    !orderData.deliveryType ||
    !orderData.paymentMethod;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await cartService.getItems();

      if (data.success) {
        setCartItems(data.data.products);
        setSummary(data.data.summary);
      } else {
        console.log(data.error);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleDelete = useCallback(
    async (id) => {
      setIsUpdating(true);

      try {
        const { data } = await cartService.removeItem(id);

        const newData = cartItems.filter((item) => item.id !== id);
        setCartItems(newData);
        setSummary(data.data.summary);
        dispatch(setCartItemsCount(cartItems.length - 1));
      } catch (e) {
        console.log(e);
      } finally {
        setIsUpdating(false);
      }
    },
    [cartItems],
  );

  const handleUpdate = useCallback(
    async (id, row) => {
      const newData = [...cartItems];
      const index = newData.findIndex((item) => id === item.id);
      const item = newData[index];

      if (!row.comment && Number(row.count) === item.count) {
        return;
      }

      if (!row.count && row.comment === item.comment) {
        return;
      }

      setIsUpdating(true);

      try {
        const { data } = await cartService.updateItem(id, row);

        if (data.success) {
          newData.splice(index, 1, {
            ...item,
            ...data.data.product,
            count: data.data.product?.count || 1,
            "total-cny": data.data.product?.[`total-${Currency.Cny}`] || 0,
            "total-usd": data.data.product?.[`total-${Currency.Usd}`] || 0,
          });

          setCartItems(newData);
          setSummary(data.data.summary);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsUpdating(false);
      }
    },
    [cartItems],
  );

  const onSuccess = useCallback(
    (result) => {
      setCartItems((data) => [...result.products, ...data]);
      setSummary(result.summary);

      dispatch(
        setCartItemsCount(
          Number(userItems.cartItemsCount) + result.products.length,
        ),
      );

      toast.success("Продукт успешно добавлен");
    },
    [userItems.cartItemsCount],
  );

  const onFail = useCallback(() => {
    toast.error("Не удалось добавить продукт в корзину");
  }, []);

  const setOrderDataField = useCallback((key, value) => {
    setOrderData((orderData) => ({ ...orderData, [key]: value }));
  }, []);

  const createOrder = useCallback(async () => {
    if (disabledCreation) {
      return;
    }

    setIsCreatingOrder(true);

    const products = cartItems.map(({ id }) => id);

    const orderDTO = {
      products,
      ...orderData,
      area: Area.Cn,
    };

    try {
      const { data } = await orderService.createOrder(orderDTO);

      if (!data.success) {
        console.log(data.error);

        return;
      }

      dispatch(
        setOrdersAndCartItemsCount({
          cartItemsCount: userItems.cartItemsCount - products.length,
          ordersCount: Number(userItems.ordersCount) + 1,
        }),
      );

      navigate(`${Routes.Orders}/${data.data.id}`);
    } catch (e) {
      console.log(e);
    } finally {
      setIsCreatingOrder(false);
    }
  }, [cartItems, orderData]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Splash>
      {isCreatingOrder && <LoaderOverlay />}
      <View>
        <Parser onSuccess={onSuccess} onFail={onFail} />
        <ProductsDataTable
          title={t("products-in-cart")}
          products={cartItems}
          summary={summary}
          isLoading={isLoading || isUpdating}
          handleDelete={handleDelete}
          handleUpdate={handleUpdate}
        />
        {cartItems.length > 0 && (
          <>
            <CartInfo
              title={t("info.delivery-payment-title")}
              setOrderDataField={setOrderDataField}
              isUpdating={isLoading}
            />
            <div className="py-6 mt-3 border-t border-gray-100 flex items-center justify-end w-full">
              <Button
                onClick={createOrder}
                disabled={disabledCreation}
                className="w-full sm:w-fit"
              >
                <div className="flex flex-row gap-4 px-2 font-semibold">
                  <div>{t("info.create-order-btn")}</div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                      />
                    </svg>
                  </div>
                </div>
              </Button>
            </div>
          </>
        )}
      </View>
    </Splash>
  );
});
