import { State } from "../root/types";
import { StateSelector } from "../types";
import { createSelector } from "@reduxjs/toolkit";
import { FetchStatus } from "../enums";
import { Address, Area } from "../../shared/types";
import { UserItemsCount, UserRole } from "./types";
import { Profile } from "../../api/profile/types";

export const getProfileState = (state: State) => state.profile;

export const getUserItems: StateSelector<{
  cartItemsCount: UserItemsCount;
  ordersCount: UserItemsCount;
}> = createSelector(getProfileState, ({ entity }) => ({
  cartItemsCount: entity?.cartItemsCount || 0,
  ordersCount: entity?.ordersCount || 0,
}));

export const isUserLoggedIn: StateSelector<boolean> = createSelector(
  getProfileState,
  ({ entity, fetchStatus }) =>
    Boolean(entity?.id) && fetchStatus === FetchStatus.Complete,
);

export const getProfile: StateSelector<Profile> = createSelector(
  getProfileState,
  ({ entity }) => entity,
);

export const getUserName: StateSelector<Profile["username"]> = createSelector(
  getProfileState,
  ({ entity }) => entity?.username,
);

export const getUserRole: StateSelector<UserRole> = createSelector(
  getProfileState,
  ({ entity }) => entity?.role || 0,
);

export const getProfileAddresses: StateSelector<Address[]> = createSelector(
  getProfileState,
  ({ entity }) => entity?.addresses || [],
);

export const getDefaultProfileAddress: StateSelector<Address> = createSelector(
  getProfileState,
  ({ entity }) => entity?.addresses.find((address) => address.default === true),
);

export const getProfileFetchStatus: StateSelector<FetchStatus> = createSelector(
  getProfileState,
  ({ fetchStatus }) => fetchStatus,
);
