import React from "react";
import { CurrencyRates } from "./CurrencyRates";
import { Links } from "./Links";

export const Footer = React.memo(() => {
  const now = new Date();

  return (
    <footer className="bottom-0 z-10 bg-gray-800 text-gray-200">
      <div className="w-full mx-auto max-w-8xl p-8 pb-12">
        <div className="grid grid-cols-4 gap-20 md:flex">
          <CurrencyRates />
          <Links />
        </div>
        <div className="w-full mx-auto mt-4 max-w-8xl text-xs flex justify-end text-slate-300">
          &copy; {now.getFullYear()} by CodeCustoms
        </div>
      </div>
    </footer>
  );
});
