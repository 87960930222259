export enum AbsoluteIntervalType {
  LastYear,
  CurrentYear,
  LastMonth,
  Last3Month,
  CurrentMonth,
  LastWeek,
  CurrentWeek,
  Yesterday,
  Today,
}
