import { RadioGroup } from "@headlessui/react";
import React, { useState } from "react";
import { PaymentMethodProps } from "./types";
import { useTranslation } from "react-i18next";

export const PaymentMethod = React.memo(
  ({ paymentMethods, setPaymentMethod }: PaymentMethodProps) => {
    const [selected, setSelected] = useState(0);
    const { t } = useTranslation("cart");

    const onChange = (value) => {
      setSelected(value);
      setPaymentMethod && setPaymentMethod(value.id);
    };

    return (
      <div className="w-full px-4 py-4">
        <h3 className="font-semibold font-2xl mb-2">
          {t("settings.payment-method")}
        </h3>
        <div className="w-full">
          <RadioGroup value={selected} onChange={onChange} className="mb-2">
            <div className="flex flex-row flex-wrap gap-2">
              {paymentMethods.map((paymentMethod) => (
                <RadioGroup.Option
                  key={paymentMethod.name}
                  value={paymentMethod}
                  className={({ checked }) =>
                    `
                  ${
                    checked
                      ? "bg-slate-900 bg-opacity-75 text-white"
                      : "bg-white text-gray-900"
                  }
                    flex cursor-pointer rounded-lg px-2 py-1 shadow-md focus:outline-none min-w-fit w-28`
                  }
                >
                  {() => (
                    <>
                      <div className="flex w-full items-center justify-center">
                        <div className="flex items-center">
                          <div className="text-sm">
                            <RadioGroup.Label as="p" className="font-medium">
                              {paymentMethod.name}
                            </RadioGroup.Label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
          <div
            className="bg-gray-50 rounded-b text-slate-900 px-4 py-3 shadow-sm mt-2"
            role="alert"
          >
            <div className="flex">
              <div className="py-1">
                <svg
                  className="fill-current h-6 w-6 text-orange-500 mr-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div>
                <p className="font-bold">{t("settings.check-details-title")}</p>
                <p className="text-sm">
                  {t("settings.check-details-line-1")}
                  {t("settings.check-details-line-2")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
