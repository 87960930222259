import { Currency, Address, Result } from "../../shared/types";
import { UserItemsCount, UserRole } from "../../store/profile";
import { ApiResponse } from "../types";

export enum UserStatus {
  Pending = 0,
  Active = 1,
  Disabled = 2,
}

export type Wallet = {
  id: number;
  name: string;
  balance: number;
  currency: Currency;
  default: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type Profile = {
  id: number;
  username: string;
  name: string;
  status: UserStatus;
  wallets: Wallet[];
  addresses: Address[];
  role: UserRole;
  phone: string;
  email: string;
  discount: number;
  cartItemsCount: UserItemsCount;
  ordersCount: UserItemsCount;
};

export type GetProfileResponse = ApiResponse<Result<Profile>>;

export type CurrencyRate = {
  createdAt: string;
  from: Currency;
  id: number;
  to: Currency;
  updatedAt: string;
  value: number;
};
