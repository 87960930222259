import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button } from "../../../lib/Controls/Button";
import { Form } from "../../../lib/Controls/Form";
import { authService } from "../../../api/auth";
import { setTokenInformation } from "../../../shared/utils";
import { Link, useNavigate } from "react-router-dom";
import { fetchUserProfile } from "../../../store/profile/thunk";
import { useDispatch, useSelector } from "react-redux";
import { getProfileFetchStatus } from "../../../store/profile";
import { FetchStatus } from "../../../store/enums";
import { Splash } from "../../../containers/Splash";
import { Routes } from "../../../config/routes";
import { PasswordInput } from "../../../lib/Input/Password";
import { fetchCurrencyRates } from "../../../store/settings/thunk";
import toast from "react-hot-toast";

export const Login = React.memo(() => {
  const { t } = useTranslation("auth");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileFetchStatus = useSelector(getProfileFetchStatus);

  const submitForm = useCallback(
    async (form) => {
      try {
        setIsLoading(true);
        const { data } = await authService.login(form);

        if (data?.success) {
          setTokenInformation(data.data);

          dispatch(fetchUserProfile());
          dispatch(fetchCurrencyRates());
        } else {
          console.log("Failed");
        }
      } catch (e) {
        console.log(e);
        toast.error(t("message.error"))
      } finally {
        setIsLoading(false);
      }
    },
    [navigate],
  );

  useEffect(() => {
    if (profileFetchStatus === FetchStatus.Complete) {
      navigate(Routes.Orders);
    }
  }, [profileFetchStatus]);

  return (
    <Splash>
      <div className="flex min-h-full flex-1 flex-col justify-center items-center px-2 sm:px-4 md:px-6 py-4 sm:py-6 md:py-8 lg:px-8">
        <div className="rounded-lg bg-gray-50 py-6 px-12 max-w-sm w-full">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h1 className="mt-6 text-center text-4xl leading-9 tracking-tight text-gray-700">
              {t("login.title")}
            </h1>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form className="space-y-6" onSubmit={submitForm}>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("login.email")}
                </label>
                <div className="mt-2">
                  <input
                    id="username"
                    name="username"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <PasswordInput
                  id={"password"}
                  name={"password"}
                  label={t("login.password")}
                />
                <div className="text-xs mt-2 flex flex-row justify-end">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    {t("login.forgot-password")}
                  </a>
                </div>
              </div>

              <div className="w-full">
                <Button type="submit" isLoading={isLoading} className="w-full">
                  {t("login.submit")}
                </Button>
              </div>
            </Form>

            <p className="mt-8 text-center text-sm text-gray-500">
              {t("login.not-a-member")}
              <Link
                to={Routes.Registration}
                className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500"
              >
                {t("login.register")}
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Splash>
  );
});
