import React, { useState } from "react";
import { CarouselProps } from "./types";

export const Carousel = React.memo(({ images }: CarouselProps) => {
  const [currentImage, setCurrentImage] = useState(0);

  const nextSlide = () => {
    if (currentImage === images.length - 1) {
      setCurrentImage(0);
    } else {
      setCurrentImage(currentImage + 1);
    }
  };

  const prevSlide = () => {
    if (currentImage === 0) {
      setCurrentImage(images.length - 1);
    } else {
      setCurrentImage(currentImage - 1);
    }
  };

  return (
    <div id="default-carousel" className="relative w-full">
      <div className="relative md:h-164 max-h-164 md:w-164 rounded-lg">
        {images.map((src, i) => (
          <div
            key={i}
            className={`${
              currentImage === i ? "" : "hidden"
            } duration-700 ease-in-out flex overflow-scroll justify-center align-middle items-center h-full`}
          >
            <img src={src} className="w-auto h-full object-contain" alt="..." />
          </div>
        ))}
      </div>
      {images.length > 1 && (
        <>
          <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-5 left-1/2">
            {images.map((src, i) => (
              <button
                key={i}
                type="button"
                onClick={() => setCurrentImage(i)}
                className={`w-2 h-2 rounded-full ${
                  currentImage === i ? "bg-slate-400" : "bg-slate-100"
                } hover:bg-slate-300`}
              ></button>
            ))}
          </div>
          <button
            type="button"
            className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={prevSlide}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-800/20 hover:bg-gray-900/30">
              <svg
                className="w-4 h-4 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
              <span className="sr-only">Previous</span>
            </span>
          </button>
          <button
            type="button"
            className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
            onClick={nextSlide}
          >
            <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-gray-800/20 hover:bg-gray-900/30">
              <svg
                className="w-4 h-4 text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="sr-only">Next</span>
            </span>
          </button>
        </>
      )}
    </div>
  );
});
