import React from "react";

export const PaymentDetails = React.memo(({ orderInfo }: any) => {
  return (
    <div className="py-8">
      <div className="w-96 h-56 m-auto bg-gradient-to-r from-gray-800 to-blue-950 rounded-xl relative text-white shadow-xl">
        <div className="w-full px-8 absolute top-8">
          <div className="flex justify-between mb-4">
            <div>
              <p className="text-xl">{orderInfo.paymentMethod.name}</p>
            </div>
          </div>
          <div className="pt-2">
            <p className="font-light">Details</p>
            <p className="font-medium tracking-more-wider">
              {orderInfo.orderPaymentDetail.paymentDetail.value}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});
