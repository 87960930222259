import React from "react";
import { CardProps } from "./types";

export const Card = ({ title, children, isLoading, unStyled }: CardProps) => {
  return (
    <div
      className={
        unStyled
          ? "block relative bg-white h-full"
          : `block relative h-full p-6 bg-white border border-gray-200 rounded-lg shadow break-words`
      }
    >
      {isLoading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden opacity-50 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
        </div>
      )}
      {title && (
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-100">
          {title}
        </h5>
      )}
      <div className={isLoading ? "blur-sm" : ""}>{children}</div>
    </div>
  );
};
