import { createAction } from "@reduxjs/toolkit";
import { FetchStatus } from "../enums";
import { Profile } from "../../api/profile/types";
import { Address } from "../../shared/types";

export const setProfile = createAction<Profile>("profile/setProfile");

export const updateProfile = createAction<Profile>("profile/updateProfile");

export const addAddress = createAction<Address>("profile/addAddress");

export const removeAddress = createAction<Address["id"]>(
  "profile/removeAddress",
);

export const updateAddress = createAction<{
  id: Address["id"];
  address: Address;
}>("profile/updateAddress");

export const flushProfile = createAction("profile/flushProfile");

export const setProfileFetchStatus = createAction<FetchStatus>(
  "profile/setProfileFetchStatus",
);

export const setCartItemsCount = createAction<number>(
  "profile/setCartItemsCount",
);

export const setOrdersCount = createAction<number>("profile/setOrdersCount");

export const setOrdersAndCartItemsCount = createAction<{
  ordersCount: number;
  cartItemsCount: number;
}>("profile/setOrdersAndCartItemsCount");
