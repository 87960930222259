import React from "react";
import { BadgeProps } from "./types";
import { twMerge } from "tailwind-merge";

const getBadgeClassNames = (color, textColor) => {
  switch (color) {
    case "dark":
      return "bg-gray-100 text-gray-800";
    case "red":
      return "bg-red-100 text-red-800";
    case "green":
      return "bg-green-100 text-green-800 rounded";
    case "yellow":
      return "bg-yellow-100 text-yellow-800";
    case "indigo":
      return "bg-indigo-100 text-indigo-800";
    case "purple":
      return "bg-purple-100 text-purple-800";
    case "pink":
      return "bg-pink-100 text-pink-800";
    case "default":
      return "bg-blue-100 text-blue-800";
    default:
      return `bg-[${color}] text-[${textColor}]`;
  }
};

export const Badge = ({ children, color, textColor }: BadgeProps) => (
  <span
    className={twMerge(
      "text-xs font-medium mr-2 px-2.5 py-0.5 rounded",
      getBadgeClassNames(color, textColor),
    )}
  >
    {children}
  </span>
);
