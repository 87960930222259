import { StateAction } from "../types";
import { createFetchAction } from "../common";
import { flushProfile, setProfile, setProfileFetchStatus } from "./actions";
import { profileService } from "../../api/profile";
import { authService } from "../../api/auth";
import { FetchStatus } from "../enums";
import { clearTokens } from "../../shared/utils";
import { GetProfileResponse, Profile } from "../../api/profile/types";
import { Result } from "../../shared/types";

export type AppDispatch = ReturnType<any>["dispatch"];

/**
 * Get properties action
 */
export const fetchUserProfile: AppDispatch =
  (): StateAction<Promise<void>> => async (dispatch) => {
    await createFetchAction<Result<Profile>>({
      set: (data) => dispatch(setProfile(data.data)),
      setFetchStatus: (fetchStatus) =>
        dispatch(setProfileFetchStatus(fetchStatus)),
      fetch: () => profileService.getProfile(),
    })();
  };

/**
 * Get properties action
 */
export const flushUserProfile: AppDispatch =
  (): StateAction<Promise<void>> => async (dispatch) => {
    await createFetchAction<any>({
      set: () => {
        dispatch(flushProfile());
        clearTokens();
      },
      setFetchStatus: () =>
        dispatch(setProfileFetchStatus(FetchStatus.Initial)),
      fetch: () => authService.logOut(),
    })();
  };
