import { TransportCompany } from "../../../shared/types";

export const getTransportCompanyTitle = (company?: TransportCompany) => {
  switch (company) {
    case TransportCompany.Cdek:
      return "CDEK";
    case TransportCompany.DelovyeLinii:
      return "Деловые Линии";
    case TransportCompany.Pek:
      return "ПЭК";
    case TransportCompany.ZheldorExpedition:
      return "ЖэлДор";
    case TransportCompany.Other:
      return "Другое";
    default:
      return "";
  }
};
