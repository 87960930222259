import React from "react";
import { Spin } from "../Spin";
import { twMerge } from "tailwind-merge";
import { ButtonProps } from "./types";

export const Button = React.memo(
  ({ children, type, view, isLoading, size, ...props }: ButtonProps) => {
    const getViewClass = () => {
      switch (view) {
        case "clear":
          return "text-gray-800 hover:bg-gray-100 focus:ring-gray-200 px-0.5 shadow-none";
        case "danger":
          return "bg-red-600 text-white hover:bg-red-500 focus:ring-red-300";
        case "ghost":
          return "bg-gray-100 text-gray-800 hover:bg-gray-200 focus:ring-gray-300";
        case "primary":
        default:
          return "bg-slate-900 text-white hover:bg-slate-700 focus:ring-slate-300";
      }
    };

    const getSizeClass = () => {
      switch (size) {
        case "xs":
          return "font-medium text-xs px-1.5 py-0.5 rounded-sm";
        case "sm":
          return "font-medium text-sm px-2.5 py-1.5 rounded-md";
        case "md":
        default:
          return "font-medium text-sm px-4 py-2 rounded-md";
      }
    };

    const getDisabledClass = () =>
      props.disabled ? "bg-gray-400 cursor-not-allowed hover:bg-gray-400" : "";

    const getLoadingClass = () => (isLoading ? "cursor-not-allowed" : "");

    return (
      <button
        type={type}
        {...props}
        className={twMerge(
          "flex justify-center items-center rounded-md focus:ring-2 focus:outline-none leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
          getSizeClass(),
          getViewClass(),
          getDisabledClass(),
          props.className,
          getLoadingClass(),
        )}
      >
        <div className="flex justify-between items-center gap-3">
          {isLoading && <Spin size={4} />}
          <div>{children}</div>
        </div>
      </button>
    );
  },
);
