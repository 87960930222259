import { Axios, AxiosResponse } from "axios";
import { FetchStatus } from "../enums";

type CreateThunkActionOptions<T> = {
  fetch: () => Promise<AxiosResponse<T>>;
  setFetchStatus: (fetchStatus: FetchStatus) => void;
  set: (data: T) => void;
  fetchStatus?: FetchStatus;
};

/**
 * Create fetch action entity with data and status
 */
export const createFetchAction = <Entity>({
  fetch,
  set,
  setFetchStatus,
  fetchStatus,
}: CreateThunkActionOptions<Entity>): (() => Promise<void>) => {
  return async () => {
    try {
      setFetchStatus(
        fetchStatus === FetchStatus.Complete
          ? FetchStatus.Updating
          : FetchStatus.Loading,
      );

      const {
        data,
        request: { status },
      } = await fetch();

      if (status >= 400) {
        setFetchStatus(FetchStatus.Error);

        return;
      }

      set(data);
      setFetchStatus(FetchStatus.Complete);
    } catch (error) {
      setFetchStatus(FetchStatus.Error);
    }
  };
};
