import React, { useCallback, useEffect, useState } from "react";
import { View } from "../../containers/View";
import { orderService } from "../../api/order";
import { OrdersDataTable } from "../../components/Orders/DataTable";
import { Splash } from "../../containers/Splash";
import { Area } from "../../shared/types";
import { DEFAULT_PAGE_SIZE } from "../../shared/constants/pagination";
import { OrderStatus } from "../../api/order/types";

export const Orders = React.memo(() => {
  const [orders, setOrders] = useState([]);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [queryParameters, setQueryParameters] = useState({
    page: 1,
    status: [],
    dates: [],
  });

  const onDatesChange = useCallback((dates: [Date, Date]) => {
    setQueryParameters((queryParameters) => ({ ...queryParameters, dates }));
  }, []);

  const onStatusesChange = useCallback((status: OrderStatus[]) => {
    setQueryParameters((queryParameters) => ({ ...queryParameters, status }));
  }, []);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { dates, page, status } = queryParameters;

      const [from, to] = dates || [];

      const { data } = await orderService.getOrders({
        area: Area.Cn,
        page,
        pageSize: DEFAULT_PAGE_SIZE,
        ...(from &&
          to && {
            from: from.toISOString(),
            to: to.toISOString(),
          }),
        ...(status && { status }),
      });

      if (data.success) {
        setOrders(data.data.orders);
        setTotalOrdersCount(data.data.total);
      } else {
        console.log(data.error);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }, [page, queryParameters]);

  useEffect(() => {
    fetchData();
  }, [page, queryParameters]);

  return (
    <Splash>
      <View>
        <OrdersDataTable
          orders={orders}
          isLoading={isLoading}
          onDatesChange={onDatesChange}
          onPageChange={(page) => setPage(page)}
          totalOrdersCount={totalOrdersCount}
          onStatusesChange={onStatusesChange}
        />
      </View>
    </Splash>
  );
});
