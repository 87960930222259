import React from "react";
import { Header } from "../../components/Header";
import { Router } from "../Router";
import { Footer } from "../../components/Footer";
import {ToastProvider} from "../../components/ToastProvider";

export const App = React.memo(() => {
  return (
    <div className="flex flex-col h-svh overflow-auto">
      <Header />
      <main className="grow">
        <Router />
      </main>
      <Footer />
      <ToastProvider/>
    </div>
  );
});
