import React, { useCallback } from "react";
import { View } from "../../containers/View";
import { useSelector } from "react-redux";
import { getProfile, getProfileFetchStatus } from "../../store/profile";
import { FetchStatus } from "../../store/enums";
import { Splash } from "../../containers/Splash";
import { AddressList } from "../../components/Profile/Address/List";
import { ProfileInfo } from "../../components/Profile/Info";

export const Profile = React.memo(() => {
  const profile = useSelector(getProfile);
  const profileFetchStatus = useSelector(getProfileFetchStatus);
  const isLoading = profileFetchStatus === FetchStatus.Loading;

  const updateProfile = useCallback((formData) => {
    console.log(formData);
  }, []);

  return (
    <Splash>
      <View>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <ProfileInfo profile={profile} updateProfile={updateProfile} />
          </div>
          <div>
            <AddressList isLoading={isLoading} addresses={profile.addresses} />
          </div>
        </div>
      </View>
    </Splash>
  );
});
