import { deleteRequest, getRequest, postRequest, putRequest } from "../index";
import {ApiResponse} from "../types";
import {Result} from "../../shared/types";
import {OrderCart, UpdatedOrderCartProduct} from "./types";

const getItems = (orderId):ApiResponse<Result<OrderCart>> =>
    getRequest(`/order-cart/${orderId}`);

const addItem = (orderId, productData): ApiResponse<Result<OrderCart>>  =>
    postRequest(`/order-cart/${orderId}/add`, productData);

const removeItem = (orderId, productId): ApiResponse<Result<Omit<OrderCart, "products">>> =>
  deleteRequest(`/order-cart/${orderId}/product/${productId}`);

const updateItem = (productId, productData): ApiResponse<Result<UpdatedOrderCartProduct>> =>
  putRequest(`/order-cart/product/${productId}`, productData);


const saveItems = (orderId, products):ApiResponse<Result<UpdatedOrderCartProduct>> =>
  postRequest(`/order-cart/${orderId}/save`, products);


export const orderCartService = {
  getItems,
  addItem,
  removeItem,
  updateItem,
  saveItems
};
