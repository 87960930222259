import React from "react";
import { OrderItemsProps } from "./types";
import { useTranslation } from "react-i18next";
import { ItemsDataTable } from "./DataTable";
import {orderService} from "../../../api/order";

export const OrderItems = React.memo(
  ({ items, isLoading, onUpdate }: OrderItemsProps) => {
    const { t } = useTranslation("order");

    return (
      <div className="mb-4">
        <div className="px-1 sm:px-0">
          <ItemsDataTable
            products={items}
            isLoading={isLoading}
            title={t("products-title")}
            handleUpdate={onUpdate}
          />
        </div>
      </div>
    );
  },
);
