import { round } from "../../shared/utils";
import { Currency } from "../../shared/types";

export const getOrderMetadata = (order: any) => {
  console.log(order);

  if (!order) {
    return {};
  }

  const {
    address,
    comment,
    costs,
    deliveryType,
    paymentMethod,
    status,
    trackNumbers,
    locked,
    createdAt,
    updatedAt,
    orderPaymentDetail,
    orderPayments,
    boxes,
  } = order;

  const totalCNY =
    Number(costs[`delivery-${Currency.Cny}`]) +
    Number(costs[`products-${Currency.Cny}`]);

  const totalUSD =
    Number(costs[`delivery-${Currency.Usd}`]) +
    Number(costs[`products-${Currency.Usd}`]);

  const paymentsUSD = orderPayments?.reduce((acc, payment) => {
    if (payment.currency === Currency.Usd) {
      acc += Number(payment.value);
    }

    return acc;
  }, 0);

  const balance = round(paymentsUSD - totalUSD).toFixed(2);

  return {
    address,
    comment,
    deliveryType,
    paymentMethod,
    status,
    trackNumbers,
    locked,
    createdAt,
    updatedAt,
    orderPaymentDetail,
    costs: {
      ...costs,
      totalCNY: totalCNY.toFixed(2),
      paymentsUSD: paymentsUSD.toFixed(2),
      totalUSD: totalUSD.toFixed(2),
      balance,
    },
    boxes,
  };
};
