import { Area, Currency, Nullable, Result } from "../../shared/types";
import { DeliveryType } from "../delivery/types";
import { PaymentMethod } from "../payment-method/types";
import { ApiResponse } from "../types";
import { Product } from "../cart/types";

enum PaymentType {
  Products = "products",
  Commission = "commission",
  Delivery = "delivery",
  Total = "total",
}

export type OrderCost = {
  id: number;
  value: number;
  currency: Currency;
  discount: number;
  type: PaymentType;
  createdAt: string;
  updatedAt: string;
};

export type OrderCostsObject = Record<string, string>;

export type Order = {
  id: number;
  address: string;
  allowedReOrder: number
  area: Area;
  locked: boolean;
  comment: string;
  costs: OrderCostsObject;
  deliveryType: DeliveryType;
  trackNumber: string;
  status: OrderStatus;
  createdAt: Date;
  updatedAt: Date;
  products: Product[];
  user: {id:number}
};

export enum OrderStatus {
  Placed = "placed",
  Cancelled = "cancelled",
  Calculated = "calculated",
  AwaitingPayment = "awaiting-payment",
  Processing = "processing",
  Purchasing = "purchasing",
  Attention = "attention",
  Ready = "ready",
  Shipped = "shipped",
}

export type CreateOrderDTO = {
  products: number[];
  deliveryType: number;
  paymentMethod: number;
  address: number;
};

export type ProductPreview = {
  title: string;
  preview: string;
};

export type OrderListItem = {
  "cost.commission": Nullable<number>;
  "cost.delivery-cn": Nullable<number>;
  "cost.delivery-ru": Nullable<number>;
  "cost.products": Nullable<number>;
  "cost.total-cny": Nullable<number>;
  "cost.total-usd": Nullable<number>;
  balance: Nullable<number>;
  "payment.total-usd": Nullable<number>;
  deliveryType: DeliveryType["name"];
  id: number;
  paymentMethod: PaymentMethod["name"];
  status: OrderStatus;
  products: ProductPreview[];
  trackNumber: Nullable<string>;
  createdAt: string;
  locked: boolean;
};

export type GetOrdersResponse = ApiResponse<
  Result<{ orders: OrderListItem[]; total: number }>
>;
export type CreateOrdersResponse = ApiResponse<Result<Order>>;
export type GetOrderResponse = ApiResponse<Result<Order>>;
export type UpdateOrderResponse = ApiResponse<Result<Order>>;
