import React, { useCallback, useEffect, useState } from "react";
import { View } from "../../containers/View";
import { Splash } from "../../containers/Splash";
import styles from "./faq.module.css";
import { faqService } from "../../api/faq";
import { LoaderOverlay } from "../../lib/LoaderOverlay";
import { FetchStatus } from "../../store/enums";


export const Faq = React.memo(() => {
  const [faqData, setFaqData] = useState("");
  const [fetchStatus, setFetchStatus] = useState<FetchStatus>(FetchStatus.Initial)

  const fetchData = async()=> {
    try {
      setFetchStatus(FetchStatus.Loading)
      const {data} =  await faqService.getFaq()
      setFaqData(data.data.content)
      setFetchStatus(FetchStatus.Complete)
    }catch(error){
      console.log(error)
      setFetchStatus(FetchStatus.Error)
    }
  }

  useEffect(()=>{
    fetchData()
  },[])

  const getContent = useCallback(()=>{
    if(fetchStatus === FetchStatus.Loading){
      return <LoaderOverlay/>
    }

    return  <View><div className="relative overflow-x-auto sm:rounded-md">
        <div className={styles.faq} dangerouslySetInnerHTML={{__html: faqData}}/>
      </div></View>
  },[faqData, fetchStatus])

  return (
    <Splash>
      {getContent()}
    </Splash>
  );
});
