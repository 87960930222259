import React from "react";
import { Button } from "../../../lib/Controls/Button";
import { useTranslation } from "react-i18next";

export const HelpBlock = React.memo(() => {
  const { t } = useTranslation("order");

  return (
    <div>
      <h3 className="text-2xl text-gray-500 mb-2">{t("help.title")}</h3>
      <div className="rounded-lg shadow-md p-4">
        <div className="mb-6">
          <label
            htmlFor="message"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            {t("help.have-a-question")}
          </label>
          <textarea
            id="message"
            rows={3}
            className="mb-2 block p-1.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
          ></textarea>
          <Button view="primary" size="sm">
            {t("help.send")}
          </Button>
        </div>
        <div
          className="bg-gray-50 rounded-b text-slate-900 px-4 py-3 shadow-sm mt-2 flex flex-col lg:flex-row gap-4 justify-between items-center"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-orange-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">{t("help.panic-hint-line-1")}</p>
              <p className="text-sm">{t("help.panic-hint-line-2")}</p>
            </div>
          </div>
          <Button view="danger" className="font-semibold whitespace-nowrap">
            {t("help.panic-button")}
          </Button>
        </div>
      </div>
    </div>
  );
});
