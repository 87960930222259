import React from "react";
import { SplashProps } from "./types";

export const Splash = React.memo(({ children }: SplashProps) => {
  return (
    <div className="w-full h-full bg-[url('https://guron.pro/img/general/header-bg.png')] bg-[#221f1a] bg-no-repeat bg-cover bg-center border-t-2 border-t-stone-700 border-b-2 border-b-stone-700">
      {children}
    </div>
  );
});
