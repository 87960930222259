export enum Routes {
  Main = "/",
  Orders = "/orders",
  Favourites = "/favourites",
  Order = "/orders/:orderId",
  Cart = "/cart",
  Login = "/login",
  Registration = "/register",
  Faq = "/faq",
  Profile = "/profile",
}
