import React, {memo, useCallback, useEffect, useState} from "react";
import {Parser} from "../../../components/Parser";
import {ProductsDataTable} from "../../../components/Products/DataTable";
import {Button} from "../../../lib/Controls/Button";
import {CartSummary, ProductStatus} from "../../../api/cart/types";
import {setCartItemsCount} from "../../../store/profile/actions";
import {toast} from "react-hot-toast";
import {orderCartService} from "../../../api/order-cart";
import {Currency} from "../../../shared/types";
import {useDispatch, useSelector} from "react-redux";
import {getUserItems} from "../../../store/profile";
import {useParams} from "react-router-dom";
import {OrderCartProps} from "./type";
import {useTranslation} from "react-i18next";


export const OrderCart = memo(({onUpdateOrder}:OrderCartProps)=> {
    const [allowedReOrder, setAllowedReOrder] = useState(0);
    const [cartItems, setCartItems] = useState([]);
    const [cartSummary, setCartSummary] = useState<CartSummary>();
    const [isCartUpdating, setIsCartUpdating] = useState(false);
    const { orderId } = useParams();
    const dispatch = useDispatch();
    const userItems = useSelector(getUserItems);
    const { t } = useTranslation("order");



    const onCartSuccess = useCallback(
        (result) => {
            setCartItems((data) => [...result.products, ...data]);
            setCartSummary(result.summary);

            dispatch(
                setCartItemsCount(
                    Number(userItems.cartItemsCount) + result.products.length,
                ),
            );

            toast.success(t("info.product-added"));
        },
        [userItems.cartItemsCount],
    );

    const onCartFail = useCallback(() => {
        toast.error("Не удалось добавить продукт в корзину");
    }, []);


    const fetchOrderCart = useCallback(async () => {
        try {
            setIsCartUpdating(true);

            const { data } = await orderCartService.getItems(orderId);

            if (data.success) {
                setCartItems(data.data.products);
                setCartSummary(data.data.summary);
                setAllowedReOrder(data.data.allowedReOrder);
            } else {
                toast.error(data.error)
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsCartUpdating(false);
        }
    }, [orderId]);

    const handleCartDelete = useCallback(
        async (id) => {
            setIsCartUpdating(true);

            try {
                const { data } = await orderCartService.removeItem(orderId, id);

                if(data.success){
                    const newData = cartItems.filter((item) => item.id !== id);
                    setCartItems(newData);
                    setCartSummary(data.data.summary);
                    setAllowedReOrder(data.data.allowedReOrder);
                    dispatch(setCartItemsCount(cartItems.length - 1));
                    toast.success(t("info.product-deleted"));
                }
            } catch (e) {
                console.log(e);
            } finally {
                setIsCartUpdating(false);
            }
        },
        [cartItems, orderId],
    );

    const handleCartUpdate = useCallback(
        async (id, row) => {
            const newData = [...cartItems];
            const index = newData.findIndex((item) => id === item.id);
            const item = newData[index];

            if (!row.comment && Number(row.count) === item.count) {
                return;
            }

            if (!row.count && row.comment === item.comment) {
                return;
            }

            setIsCartUpdating(true);

            try {
                const { data } = await orderCartService.updateItem(id, row);

                if (data.success) {
                    newData.splice(index, 1, {
                        ...item,
                        ...data.data.product,
                        count: data.data.product?.count || 1,
                        "total-cny": data.data.product?.[`total-${Currency.Cny}`] || 0,
                        "total-usd": data.data.product?.[`total-${Currency.Usd}`] || 0,
                    });

                    setCartItems(newData);
                    setCartSummary(data.data.summary);
                    setAllowedReOrder(data.data.allowedReOrder);
                    toast.success(t("info.product-updated"));
                }
            } catch (e) {
                console.log(e);
            } finally {
                setIsCartUpdating(false);
            }
        },
        [cartItems],
    );


    const handleAddProductsToOrder = useCallback(async ()=> {
        setIsCartUpdating(true);

        try {
            const { data } = await orderCartService.saveItems(orderId, cartItems.map(item => item.id))


            if (data.success) {

                onUpdateOrder(prevState => { return {...prevState, products:[...prevState.products, ...cartItems.map(item => {return {...item, status: ProductStatus.Ordered}})]}})
                setCartItems([]);
                setCartSummary(data.data.summary);
                setAllowedReOrder(data.data.allowedReOrder);
                toast.success(t("info.updated"));
            }else {
                toast.error(data.error)
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsCartUpdating(false);
        }
    },[
        orderId, cartItems
    ])



    useEffect(() => {
        fetchOrderCart()
    }, [orderId]);


    return   (cartItems.length !== 0 || allowedReOrder !== 0) && <>
        {allowedReOrder >  cartItems.length && <Parser orderId={orderId} onSuccess={onCartSuccess} onFail={onCartFail} type={"in-order-cart"} />}
        <ProductsDataTable
            title={t("products-in-cart")}
            products={cartItems}
            summary={cartSummary}
            isLoading={ isCartUpdating}
            handleDelete={handleCartDelete}
            handleUpdate={handleCartUpdate}
        />
        <div className="py-6 mt-3 border-t border-gray-100 flex items-center justify-end w-full">
            <Button

                className="w-full sm:w-fit"
                onClick={handleAddProductsToOrder}
                isLoading={isCartUpdating}
            >
                <div className="flex flex-row gap-4 px-2 font-semibold">
                    <div>{t("update-order-btn")}</div>
                    <div>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                            />
                        </svg>
                    </div>
                </div>
            </Button>
        </div>
    </>
})