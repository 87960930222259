import React from "react";
import { OrderStatusProps } from "./types";
import { Badge } from "../../lib/Badge";
import { OrderStatus as OrderStatusType } from "../../api/order/types";
import { useTranslation } from "react-i18next";
import { CSSColors } from "../../lib/Badge/types";

const getStatusColor = (status: OrderStatusType): [CSSColors, CSSColors] => {
  switch (status) {
    case OrderStatusType.Attention:
      return ["#dc930d", "#fff"];
    case OrderStatusType.Placed:
      return ["#eaeaea", "#343f4b"];
    case OrderStatusType.Ready:
      return ["#13b246", "#fff"];
    case OrderStatusType.Calculated:
      return ["#864a93", "#fff"];
    case OrderStatusType.Cancelled:
      return ["#dc0e0e", "#fff"];
    case OrderStatusType.AwaitingPayment:
      return ["#daae5c", "#fff"];
    case OrderStatusType.Processing:
      return ["#0f8cd7", "#fff"];
    case OrderStatusType.Shipped:
      return ["#23915a", "#fff"];
    case OrderStatusType.Purchasing:
      return ["#234291", "#fff"];

    default:
      return ["#888888", "#fff"];
  }
};

export const OrderStatus = React.memo(({ status }: OrderStatusProps) => {
  const { t } = useTranslation("order");
  const [color, textColor] = getStatusColor(status);

  return (
    <Badge color={color} textColor={textColor}>
      {t(`status.${status}`)}
    </Badge>
  );
});
