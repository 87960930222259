import { configureStore } from "@reduxjs/toolkit";
import { middleware, rootReducer } from "./root";

export const createStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware,
    preloadedState: {},
  });
};
