import { getRequest, postRequest, putRequest } from "../index";
import {
  CreateOrderDTO,
  CreateOrdersResponse,
  GetOrderResponse,
  GetOrdersResponse,
  UpdateOrderResponse,
} from "./types";
import { Area } from "../../shared/types";
import { buildParams } from "../../shared/utils/url";
import { Product } from "../cart/types";

const getOrders = (params: any): GetOrdersResponse => {
  const queryString = buildParams(params);

  return getRequest(`/order${queryString ? `?${queryString}` : ""}`);
};

const createOrder = (orderDTO: CreateOrderDTO): CreateOrdersResponse =>
  postRequest("/order", orderDTO);

const getOrder = (orderId: number, area = Area.Cn): GetOrderResponse =>
  getRequest(`/order/${orderId}?area=${area}`);

const updateProduct = (
  orderId: number,
  productId: number,
  item: Product,
): UpdateOrderResponse =>
  putRequest(`/order/${orderId}/product/${productId}`, item);

export const orderService = {
  getOrder,
  getOrders,
  createOrder,
  updateProduct,
};
