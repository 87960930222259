/**
 * Fetch statuses
 */
export enum FetchStatus {
  Initial = "initial",
  Loading = "loading",
  Updating = "updating",
  Complete = "complete",
  Error = "error",
}
