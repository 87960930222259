import React, { useCallback, useState } from "react";
import { Combobox, Listbox, RadioGroup } from "@headlessui/react";
import { AddressType, Country } from "../../../../shared/types";
import { useTranslation } from "react-i18next";
import { Form } from "../../../../lib/Controls/Form";
import { Button } from "../../../../lib/Controls/Button";
import { profileService } from "../../../../api/profile";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { AddAddressFormProps } from "./types";
import { getTransportCompanyTitle } from "../helper";
import { transportCompanies } from "../constants";

export const AddAddressForm = React.memo(
  ({
    onClose,
    appendAddress,
    countriesList,
    addressTypes,
  }: AddAddressFormProps) => {
    const { t } = useTranslation("profile");
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");

    const filteredCountries =
      query === ""
        ? countriesList
        : countriesList.filter((country) =>
            (country?.nameRu || country.name)
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query.toLowerCase().replace(/\s+/g, "")),
          );

    const submitForm = useCallback(async (values) => {
      setIsLoading(true);

      const {
        addressType: type,
        city,
        "country[id]": country,
        postalCode,
        transportCompany,
        addressLine,
        contact,
        defaultAddress,
      } = values;

      try {
        const { data } = await profileService.addAddress({
          value: `${postalCode}, ${city}, ${addressLine}`,
          type,
          country,
          transportCompany,
          contact,
          default: defaultAddress,
        });

        if (!data.success) {
          console.log("Error");
        } else {
          appendAddress(data.data);
          onClose();
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }, []);

    return (
      <div className="rounded-md shadow-lg p-4 my-2">
        <h3 className="text-2xl text-gray-700 my-4">
          {t("address-form.title")}
        </h3>
        <Form className="space-y-4" onSubmit={submitForm}>
          <div className="grid md:grid-cols-3 md:gap-6">
            <div className="relative z-0 w-full mb-4 group col-span-2">
              <label className="text-xs text-gray-500">
                {t("address-form.type-title")}
              </label>
              <RadioGroup
                id={"addressType"}
                name={"addressType"}
                defaultValue={AddressType.Home}
              >
                <div className="flex flex-row flex-wrap gap-2 mt-1">
                  {addressTypes.map(({ id, title }) => (
                    <RadioGroup.Option
                      key={id}
                      value={id}
                      className={({ checked }) =>
                        `${
                          checked
                            ? "bg-slate-900 bg-opacity-75 text-white"
                            : "bg-white text-gray-900"
                        }
                    flex cursor-pointer rounded-md px-2 py-1 shadow-md focus:outline-none min-w-fit`
                      }
                    >
                      {() => (
                        <>
                          <div className="flex w-full items-center justify-between gap-12 p-0.5">
                            <div className="flex items-center">
                              <div className="text-xs">
                                <RadioGroup.Label
                                  as="p"
                                  className="font-medium"
                                >
                                  {title}
                                </RadioGroup.Label>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
            </div>
            <div className="flex flex-row items-start md:justify-end mb-4">
              <div className="flex items-center">
                <label
                  htmlFor="defaultAddress"
                  className="mr-2 text-xs font-medium text-gray-500"
                >
                  {t("address-form.default-address")}
                </label>
                <input
                  id="defaultAddress"
                  name="defaultAddress"
                  type="checkbox"
                  className="w-4 h-4 text-slate-600 cursor-pointer bg-gray-100 border-gray-300 rounded"
                />
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-10 w-full group">
              <label className="text-xs text-gray-500">
                {t("address-form.country")}
              </label>
              <Combobox name="country" defaultValue={""}>
                <div className="relative mt-1">
                  <div className="relative w-full cursor-default overflow-hidde text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                    <Combobox.Input
                      className="block py-1.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600"
                      displayValue={(country: Country) =>
                        country?.nameRu || country.name
                      }
                      onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Combobox.Button>
                  </div>
                  <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {filteredCountries.length === 0 && query !== "" ? (
                      <div className="relative cursor-pointer select-none py-2 px-4 text-gray-700 text-sm">
                        {t("address-form.nothing-found")}
                      </div>
                    ) : (
                      filteredCountries.map((country) => (
                        <Combobox.Option
                          key={country.id}
                          className={({ active }) =>
                            `relative cursor-pointer select-none py-2 pl-8 pr-4 text-sm ${
                              active
                                ? "bg-gray-600 text-white"
                                : "text-gray-900"
                            }`
                          }
                          value={country}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {country?.nameRu || country.name}
                              </span>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                    active ? "text-white" : "text-teal-600"
                                  }`}
                                ></span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      ))
                    )}
                  </Combobox.Options>
                </div>
              </Combobox>
            </div>
            <div className="relative w-full mt-3 sm:mt-0 group">
              <label className="text-xs text-gray-500">
                {t("address-form.post-code")}
              </label>
              <input
                type="number"
                name="postalCode"
                id="postalCode"
                className="block py-1.5 pt-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative w-full group">
              <label className="text-xs text-gray-500">
                {t("address-form.city")}
              </label>
              <input
                type="text"
                name="city"
                id="city"
                className="block py-1.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
              />
            </div>
            <div className="relative w-full mt-3 sm:mt-0 group">
              <label className="text-xs text-gray-500">
                {t("address-form.address-line")}
              </label>
              <input
                type="text"
                name="addressLine"
                id="addressLine"
                className="block py-1.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                required
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 md:gap-6">
            <div className="relative z-10 w-full group">
              <label className="text-xs text-gray-500">
                {t("address-form.transport-company")}
              </label>
              <Listbox name="transportCompany" defaultValue={""}>
                <div className="relative mt-1">
                  <Listbox.Button className="relative w-full cursor-pointer bg-white py-1.5 pl-0.5 pr-6 border-b-2 border-gray-300 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    {({ value }) => (
                      <>
                        <span
                          className={twMerge(
                            "block truncate text-gray-500",
                            !value && "pb-5",
                          )}
                        >
                          {value && getTransportCompanyTitle(value)}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </>
                    )}
                  </Listbox.Button>
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {transportCompanies.map((transportCompany) => (
                      <Listbox.Option
                        key={transportCompany}
                        className={({ active }) =>
                          `relative cursor-pointer select-none py-2 pl-8 pr-4 text-sm ${
                            active ? "bg-gray-600 text-white" : "text-gray-900"
                          }`
                        }
                        value={transportCompany}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selected ? "font-medium" : "font-normal"
                              }`}
                            >
                              {getTransportCompanyTitle(transportCompany)}
                            </span>
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </div>
              </Listbox>
            </div>
            <div className="relative z-0 w-full mb-6 mt-3 sm:mt-0 group">
              <label className="text-xs text-gray-500">
                {t("address-form.contact")}
              </label>
              <input
                type="text"
                name="contact"
                id="contact"
                className="block py-1.5 pt-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=" "
                required
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap gap-2">
            <Button type="submit" size={"sm"} isLoading={isLoading}>
              {t("address-form.add")}
            </Button>
            <Button type="button" size={"sm"} onClick={onClose} view="ghost">
              {t("address-form.cancel")}
            </Button>
          </div>
        </Form>
      </div>
    );
  },
);
