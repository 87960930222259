import React, { useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Button } from "../../../lib/Controls/Button";
import { Form } from "../../../lib/Controls/Form";
import { authService } from "../../../api/auth";
import { setTokenInformation } from "../../../shared/utils";
import { useNavigate } from "react-router-dom";
import { fetchUserProfile } from "../../../store/profile/thunk";
import { useDispatch, useSelector } from "react-redux";
import { getProfileFetchStatus } from "../../../store/profile";
import { FetchStatus } from "../../../store/enums";
import { Splash } from "../../../containers/Splash";
import { Routes } from "../../../config/routes";
import { PasswordInput } from "../../../lib/Input/Password";
import { fetchCurrencyRates } from "../../../store/settings/thunk";
import { twMerge } from "tailwind-merge";
import toast from "react-hot-toast";

export const Registration = React.memo(() => {
  const { t } = useTranslation("auth");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileFetchStatus = useSelector(getProfileFetchStatus);
  const [error, setError] = useState(false);

  const submitForm = useCallback(
    async (form: any) => {
      if (form.password !== form.passwordConfirm) {
        setError(true);

        return;
      }

      try {
        setIsLoading(true);
        const { data } = await authService.register(form);

        if (data?.success) {
          setTokenInformation(data.data);

          dispatch(fetchUserProfile());
          dispatch(fetchCurrencyRates());
        } else {
          //@ts-ignore
          toast.error(data?.error?.driverError?.detail || t("message.error"))
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [navigate],
  );

  const checkMatch = () => setError(false);

  useEffect(() => {
    if (profileFetchStatus === FetchStatus.Complete) {
      navigate(Routes.Orders);
    }
  }, [profileFetchStatus]);

  return (
    <Splash>
      <div className="flex min-h-full flex-1 flex-col justify-center items-center px-2 sm:px-4 md:px-6 py-4 sm:py-6 md:py-8 lg:px-8">
        <div className="rounded-lg bg-gray-50 py-6 px-12 max-w-sm w-full">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <h1 className="mt-6 text-center text-4xl leading-9 tracking-tight text-gray-700">
              {t("registration.title")}
            </h1>
          </div>
          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <Form className="space-y-6" onSubmit={submitForm}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registration.email")}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registration.name")}
                </label>
                <div className="mt-2">
                  <input
                    id="name"
                    name="name"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  {t("registration.phone")}
                </label>
                <div className="mt-2">
                  <input
                    id="phone"
                    name="phone"
                    required
                    className="block w-full rounded-md border-0 px-1.5 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <PasswordInput
                id={"password"}
                name={"password"}
                label={t("registration.password")}
                onChange={checkMatch}
              />
              <PasswordInput
                id={"passwordConfirm"}
                name={"passwordConfirm"}
                label={t("registration.password-confirm")}
                onChange={checkMatch}
              />
              <span
                className={twMerge(
                  !error && "hidden",
                  "text-xs text-red-500 peer-[&:not(:placeholder-shown):not(:focus):invalid]:block",
                )}
              >
                Passwords did not match
              </span>
              <div className="w-full py-6">
                <Button type="submit" isLoading={isLoading} className="w-full">
                  {t("registration.submit")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Splash>
  );
});
