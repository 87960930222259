import { getTokenHeader } from "../shared/utils";

export const applyRequestInterceptor = (api) => {
  api.interceptors.request.use(
    (config) => {
      const authToken = getTokenHeader();

      if (authToken) {
        config.headers.Authorization = authToken;
      }

      return config;
    },
    (error) => {
      return error;
    },
  );
};
