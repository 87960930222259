import React, { useState } from "react";
import { OrderInfoProps } from "./types";
import { OrderStatus } from "../../OrderStatus";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../lib/Modal";
import { PaymentDetails } from "./PaymentDetails";

export const OrderInfo = React.memo(({ orderInfo }: OrderInfoProps) => {
  const { t } = useTranslation("order");
  const [isPaymentInfoVisible, setIsPaymentInfoVisible] = useState(false);

  const closePaymentInfoModal = () => setIsPaymentInfoVisible(false);

  const getTotals = (costs) => {
    return (
      <>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
          {costs.totalCNY} ¥
        </dd>
        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
          {costs.totalUSD} $
        </dd>
      </>
    );
  };

  return (
    <div className="sm:grid-cols-2 sm:grid gap-6 mt-4">
      <div className="mb-4 sm:border-r sm:border-gray-100 sm:pr-6">
        <div className="px-1 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            {t("info.title")}
          </h3>
        </div>
        {orderInfo && (
          <div className="mt-2 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.status")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                  <OrderStatus status={orderInfo.status} />
                </dd>
              </div>
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.address")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                  {orderInfo.address}
                </dd>
              </div>
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.delivery")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                  {orderInfo.deliveryType?.name}
                </dd>
              </div>
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.track-number")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                  {orderInfo.trackNumber}
                </dd>
              </div>
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.boxes-weight")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-3 sm:mt-0">
                  {orderInfo.boxes?.reduce((acc, { weight }) => {
                    acc += Number(weight);
                    return acc;
                  }, 0).toFixed(2)}{" "}
                  {t("info.kg")}
                  <span className="sm:ml-4 ml-2 text-gray-500 text-xs">
                    {orderInfo.boxes.length}
                    {t("info.boxes-count")}
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        )}
      </div>
      <div className="mb-4">
        <div className="px-1 sm:px-0">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            {t("info.payment-details-title")}
          </h3>
        </div>
        {orderInfo && (
          <div className="mt-2 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.total")}
                </dt>
                {getTotals(orderInfo.costs)}
              </div>
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.payment-method")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                  <div className="flex flex-row gap-2 items-center">
                    <div> {orderInfo.paymentMethod?.name}</div>
                    <div className="flex">
                      <button onClick={() => setIsPaymentInfoVisible(true)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5 hover:brightness-75"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </dd>
              </div>
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.discount")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
                  <div className="flex flex-row gap-2 items-center">
                    <div> {orderInfo.discount}</div>
                  </div>
                </dd>
              </div>
              <div className="px-1 py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  {t("info.balance")}
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0 font-semibold">
                  <div className="flex flex-row gap-2 items-center">
                    <div>{orderInfo.costs.balance} $</div>
                    <div>
                      {Number(orderInfo.costs.balance) < 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4 text-red-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="currentColor"
                          className="w-4 h-4 text-green-600"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        )}
      </div>
      <Modal
        title={t("payment-preview")}
        visible={isPaymentInfoVisible}
        onClose={closePaymentInfoModal}
        w={"max-w-lg"}
        h={"h-164 sm:h-128"}
      >
        <PaymentDetails orderInfo={orderInfo}></PaymentDetails>
      </Modal>
    </div>
  );
});
