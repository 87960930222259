import React from "react";

export const Form = React.memo(({ onSubmit, children, ...props }: any) => {
  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {};
    const formData = new FormData(event.currentTarget as HTMLFormElement);
    formData.forEach((value, property: string) => (data[property] = value));

    onSubmit(data);

    return false;
  };

  return (
    <form {...props} onSubmit={submitForm}>
      {children}
    </form>
  );
});
