import React from "react";
import { ProductPreviewProps } from "./types";

export const ProductsPreview = React.memo(
  ({ productPreviews }: ProductPreviewProps) => {
    return (
      <div className="flex flex-row gap-0.5 w-32">
        {productPreviews?.map((p) => (
          <div key={p.title}>
            <img
              className="rounded-sm shadow-sm object-cover h-10 w-10 min-w-max"
              src={p.preview}
              alt={p.title}
            />
          </div>
        ))}
      </div>
    );
  },
);
