import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FetchStatus } from "../enums";
import { SystemSettingsReducer, SettingsState } from "./types";

export const settingsReducer = createSlice<
  SettingsState,
  SystemSettingsReducer
>({
  name: "settings",
  initialState: {
    fetchStatus: FetchStatus.Initial,
    entity: {},
  },
  reducers: {
    setCurrencyRates(state: SettingsState, action: PayloadAction<any>) {
      state.entity.currencyRates = action.payload;
    },
    setSettingsFetchStatus(
      state: SettingsState,
      action: PayloadAction<FetchStatus>
    ) {
      state.fetchStatus = action.payload;
    },
  },
});

export const { setCurrencyRates, setSettingsFetchStatus } =
  settingsReducer.actions;
