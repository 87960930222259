import React, { useCallback } from "react";
import { AbsoluteIntervalType } from "../enums";
import {
  subWeeks,
  subMonths,
  subYears,
  lastDayOfYear,
  startOfYear,
  startOfMonth,
  lastDayOfMonth,
  startOfWeek,
  lastDayOfWeek,
  startOfDay,
  endOfDay,
  endOfYear,
  endOfMonth,
} from "date-fns";
import { DatepickerContainerProps } from "./types";

export const DatepickerContainer = ({
  className,
  children,
  onChange,
}: DatepickerContainerProps) => {
  const setInterval = useCallback(
    (type: AbsoluteIntervalType) => {
      const intervals = [];
      const now = new Date();

      switch (type) {
        case AbsoluteIntervalType.LastYear: {
          const previousYear = subYears(now, 1);
          intervals.push(
            startOfYear(previousYear),
            endOfDay(lastDayOfYear(previousYear)),
          );
          break;
        }
        case AbsoluteIntervalType.CurrentYear: {
          intervals.push(startOfYear(now), endOfDay(endOfYear(now)));
          break;
        }
        case AbsoluteIntervalType.LastMonth: {
          const previousMonth = subMonths(now, 1);
          intervals.push(
            startOfMonth(previousMonth),
            endOfDay(lastDayOfMonth(now)),
          );
          break;
        }
        case AbsoluteIntervalType.Last3Month: {
          const previous3Month = subMonths(now, 3);
          intervals.push(
            startOfMonth(previous3Month),
            endOfDay(lastDayOfMonth(now)),
          );
          break;
        }
        case AbsoluteIntervalType.CurrentMonth: {
          intervals.push(startOfMonth(now), endOfDay(endOfMonth(now)));
          break;
        }
        case AbsoluteIntervalType.LastWeek: {
          const previousWeek = subWeeks(now, 1);
          intervals.push(
            startOfWeek(previousWeek),
            endOfDay(lastDayOfWeek(previousWeek)),
          );
          break;
        }
        case AbsoluteIntervalType.Today:
        default: {
          intervals.push(startOfDay(now), endOfDay(now));
          break;
        }
      }

      onChange && onChange(intervals);
    },
    [onChange],
  );

  return (
    <div className={className}>
      <div className="flex flex-row shadow-xl">
        <div className="px-1 py-2 bg-gray-700 rounded-l">
          <div
            className="text-gray-50 py-1 px-2 hover:bg-gray-600 rounded-sm cursor-pointer"
            onClick={() => setInterval(AbsoluteIntervalType.Today)}
          >
            Today
          </div>
          <div
            className="text-gray-50 py-1 px-2 hover:bg-gray-600 rounded-sm cursor-pointer"
            onClick={() => setInterval(AbsoluteIntervalType.LastWeek)}
          >
            Last week
          </div>
          <div
            className="text-gray-50 py-1 px-2 hover:bg-gray-600 rounded-sm cursor-pointer"
            onClick={() => setInterval(AbsoluteIntervalType.CurrentMonth)}
          >
            Current month
          </div>
          <div
            className="text-gray-50 py-1 px-2 hover:bg-gray-600 rounded-sm cursor-pointer"
            onClick={() => setInterval(AbsoluteIntervalType.LastMonth)}
          >
            Last month
          </div>
          <div
            className="text-gray-50 py-1 px-2 hover:bg-gray-600 rounded-sm cursor-pointer"
            onClick={() => setInterval(AbsoluteIntervalType.Last3Month)}
          >
            Last 3 months
          </div>
          <div
            className="text-gray-50 py-1 px-2 hover:bg-gray-600 rounded-sm cursor-pointer"
            onClick={() => setInterval(AbsoluteIntervalType.CurrentYear)}
          >
            Current year
          </div>
          <div
            className="text-gray-50 py-1 px-2 hover:bg-gray-600 rounded-sm cursor-pointer"
            onClick={() => setInterval(AbsoluteIntervalType.LastYear)}
          >
            Last year
          </div>
        </div>
        <div className="relative rounded-r">{children}</div>
      </div>
    </div>
  );
};
