import React from "react";
import { Splash } from "../../containers/Splash";
import { Parser } from "../../components/Parser";
import { useSelector } from "react-redux";
import { isUserLoggedIn } from "../../store/profile";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../config/routes";
import {useTranslation} from "react-i18next";

export const Main = React.memo(() => {
  const navigate = useNavigate();
  const {t} = useTranslation("main")

  const onSuccess = () => {
    navigate(Routes.Cart);
  };

  const onFail = () => console.log("failed");

  const isLoggedIn = useSelector(isUserLoggedIn);

  return (
    <Splash>
      <div className="flex min-h-full flex-col flex-1 items-center px-6 py-12 lg:px-8">
        <div className="mt-20 mb-12">
          <img src="https://guron.pro/img/general/logo.png" alt="" />
        </div>

        <div className="mb-4 md:w-2/3 w-full">
          {isLoggedIn && (
            <Parser onSuccess={onSuccess} onFail={onFail} hideTitle />
          )}
        </div>

        <h3 className="text-white text-3xl mb-4 font-light">
          Узнайте, что такое качественный сервис доставки грузов и посылок!
        </h3>
        <div className="mt-6">
          <ol className="flex-wrap flex items-center w-full text-sm font-medium text-center text-slate-200 sm:text-base md:gap-8 gap-6">
            <li>{t("step.register")}</li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
            </li>
            <li>{t("step.faq")}</li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
            </li>
            <li>{t("step.order")}</li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
            </li>
            <li>{t("step.pay")}</li>
            <li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                />
              </svg>
            </li>
            <li>{t("step.parcel")}</li>
          </ol>
        </div>
      </div>
    </Splash>
  );
});
