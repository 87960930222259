import React, { useRef } from "react";
import { EditableCellProps } from "./types";
import { RowData } from "@tanstack/react-table";

export const EditableCell = <T extends RowData>({
  row,
  type = "text",
  onUpdate,
}: EditableCellProps<T>) => {
  const ref = useRef(null);

  const {
    getValue,
    row: { index },
    column: { id },
  } = row;

  const initialValue = getValue();
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  // When the input is blurred, we'll call our table meta's updateData function
  const onBlur = () => {
    if (value !== initialValue) {
      onUpdate && onUpdate(index, id, value);
    }
  };

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      onBlur();
      ref.current.blur();
    }
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const getEditableTag = () => {
    switch (type) {
      case "textarea":
        return (
          <textarea
            value={value || ("" as string)}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            className="block w-[300px] rounded-sm border-0 px-1 outline-none py-1 text-sky-700 shadow-sm ring-1 ring-gray-50 hover:ring-gray-300 focus:ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
        );
      case "number":
        return (
          <input
            type={"number"}
            ref={ref}
            value={value || ("" as string)}
            min={1}
            max={9999999}
            step={1}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            onKeyDown={onEnter}
            className="block w-full rounded-sm border-0 px-1 outline-none py-0.5 text-sky-700 shadow-sm ring-1 ring-gray-50 hover:ring-gray-300 focus:ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
        );
      case "text":
      default:
        return (
          <input
            ref={ref}
            value={value || ("" as string)}
            onChange={(e) => setValue(e.target.value)}
            onBlur={onBlur}
            onKeyDown={onEnter}
            className="block w-full rounded-sm border-0 px-1 outline-none py-0.5 text-sky-700 shadow-sm ring-1 ring-gray-50 hover:ring-gray-300 focus:ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
          />
        );
    }
  };

  return getEditableTag();
};
