import React, { useCallback, useEffect, useState } from "react";
import { CartAddress } from "./Address";
import { DeliveryType } from "./DeliveryType";
import { PaymentMethod } from "./PaymentMethod";
import { Card } from "../../lib/Card";
import { useSelector } from "react-redux";
import {
  getProfileAddresses,
  getProfileFetchStatus,
} from "../../store/profile";
import { FetchStatus } from "../../store/enums";
import { cartService } from "../../api/cart";
import { CartInfo as CartInfoType } from "../../api/cart/types";

export const CartInfo = React.memo(
  ({ title, setOrderDataField, isUpdating }: any) => {
    const [cartMetaData, setCartMetaData] = useState<CartInfoType>({
      deliveryTypes: [],
      paymentMethods: [],
    });
    const [isLoading, setIsLoading] = useState(false);
    const userAddresses = useSelector(getProfileAddresses);
    const profileFetchStatus = useSelector(getProfileFetchStatus);

    const fetchCartMetaData = useCallback(async () => {
      try {
        setIsLoading(true);

        const { data } = await cartService.getCartInfo();

        if (!data.success) {
          console.log(data.error);

          return;
        }

        setCartMetaData(data.data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }, []);

    const setDeliveryType = useCallback((value) => {
      setOrderDataField("deliveryType", value);
    }, []);

    const setPaymentMethod = useCallback((value) => {
      setOrderDataField("paymentMethod", value);
    }, []);

    const setAddress = useCallback((value) => {
      setOrderDataField("address", value);
    }, []);

    const setAddressString = useCallback((value: string) => {
      setOrderDataField("addressString", value);
    }, []);

    useEffect(() => {
      fetchCartMetaData();
    }, []);

    return (
      <div>
        <h2 className="text-gray-700 mb-2 text-2xl font-semibold">{title}</h2>
        <div
          className="relative overflow-x-auto bg-white rounded-lg"
          style={{ minHeight: "11rem" }}
        >
          <Card
            unStyled
            isLoading={
              isLoading ||
              isUpdating ||
              profileFetchStatus === FetchStatus.Loading
            }
          >
            <PaymentMethod
              paymentMethods={cartMetaData.paymentMethods}
              setPaymentMethod={setPaymentMethod}
            />
            <CartAddress
              userAddresses={userAddresses}
              setAddress={setAddress}
              setAddressString={setAddressString}
            />
            <DeliveryType
              deliveryTypes={cartMetaData.deliveryTypes}
              setDeliveryType={setDeliveryType}
            />
          </Card>
        </div>
      </div>
    );
  },
);
