import { deleteRequest, getRequest, postRequest, putRequest } from "../index";
import { ApiResponse } from "../types";
import { Cart, CartInfo, PromoApplied, UpdatedCartProduct } from "./types";
import { Result } from "../../shared/types";

const addToCart = (data): ApiResponse<Result<Cart>> =>
  postRequest("/cart/add", data);

const applyPromo = (data): ApiResponse<Result<PromoApplied>> =>
  postRequest("/cart/apply-promo", data);

const getItems = (): ApiResponse<Result<Cart>> => getRequest("/cart");

const getCartInfo = (): ApiResponse<Result<CartInfo>> =>
  getRequest("/cart/info");

const removeItem = (id): ApiResponse<Result<Omit<Cart, "products">>> =>
  deleteRequest(`/cart/${id}`);

const updateItem = (id, item): ApiResponse<Result<UpdatedCartProduct>> =>
  putRequest(`/cart/product/${id}`, item);

const uploadImage = (id: number, file): ApiResponse<Result<any>> => {
  const formData = new FormData();
  formData.append("file", file);

  return postRequest(`/cart/product/${id}/upload-image`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const cartService = {
  getItems,
  addToCart,
  removeItem,
  applyPromo,
  updateItem,
  getCartInfo,
  uploadImage,
};
