import { getRequest, postRequest } from "../index";
import { ApiResponse } from "../types";
import { UserLogin, UserRegister } from "./types";
import { Result } from "../../shared/types";

const login = (body: UserLogin): ApiResponse<Result<any>> =>
  postRequest("/auth/login", body);

const register = (body: UserRegister): ApiResponse<Result<any>> =>
  postRequest("/auth/register", body);

const logOut = (): ApiResponse<void> => getRequest("/auth/logout");

const changePassword = (data): ApiResponse<Result<void>> =>
  postRequest("/auth/change-password", data);

export const authService = {
  login,
  logOut,
  changePassword,
  register,
};
